import { TouchableOpacity, Text, View, useWindowDimensions, Pressable, Platform, ScrollView } from "react-native";
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-native-modal';
import styles from "@stylesheet";
import LottieView from 'lottie-react-native';
import { HIDE_AUTH_MODAL, HIDE_LOADING_MODAL, REFRESH } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import React, { useEffect, useState, useCallback, useRef } from "react";
import Button from '../../UI/Button/Button';
import { BlurView } from '@constants/Themed';
import useGlobalUser from "@hooks/getGlobalUser";
import * as Animatable from 'react-native-animatable';
import { useNavigation } from "@react-navigation/native";


const AuthModal = (props) => {
  const authModal = useSelector((state: DefaultRootState) => state.modals?.authModal);
  const visibleState = useSelector((state: DefaultRootState) => state.modals?.authModal.modalDisplayed);
  const theme = useSelector((state: DefaultRootState) => state.app.theme?.lightTheme);
  const windowDimensions = useWindowDimensions();
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState();
  const [modalContent, setModalContent] = useState();
  const user = useGlobalUser();
  const [dataArray, setDataArray] = useState([]);
  const modalRef = useRef<Animatable.AnimatableAnimationMethods>(null);
  const wholeRef = useRef<Animatable.AnimatableAnimationMethods>(null);
const dispatch = useDispatch();
const navigation = props.navigation;

  useEffect(() => {
    setModalContent(authModal.modalContent);
    if (authModal.modalType === 'success') {
      console.log('success modal');
      modalRef.current?.shake?.();
      setTimeout(() => {
        wholeRef.current?.fadeOut?.(500);
        setTimeout(() => dispatch({ type: HIDE_AUTH_MODAL }), 400);
      }, 2000);
    }
  }, [authModal.modalContent, authModal.modalType]);

  const returnTypeIcon = () => {
    let Icon;
    if (authModal.modalType === 'error') {
      Icon = <LottieView autoPlay style={{ width: 100, flex: 1 }} source={require('@assets/animations/errorIcon.json')} loop={true} speed={1} />;
    } else if (authModal.modalType === 'warning') {
      Icon = <LottieView autoPlay style={{ width: 100, flex: 1 }} source={require('@assets/animations/88006-warning-yellow.json')} loop={true} speed={1} />;
    } else {
      Icon = <LottieView autoPlay style={{ width: 100, flex: 1 }} source={require('@assets/animations/registerSuccess.json')} loop={true} />;
    }
    return Icon;
  };
  if (authModal.modalContent?.includes("User not found")) {
    console.error("User not found error")
    navigation.navigate("Login")
  }
  // Check if modalContent is an array
  const messages = Array.isArray(modalContent) ? modalContent : [modalContent];

    return (
      <Modal
        animationIn={'bounceInLeft'}
        animationOut={'slideOutLeft'}
        isVisible={visibleState}
        hasBackdrop={true}
        coverScreen={true}
        backdropColor="black"
        backdropOpacity={.4}
        onModalHide={() => console.log('auth modal hidden')}
        deviceHeight={windowDimensions.height * 2}
        deviceWidth={windowDimensions.width * 2}
        onModalWillHide={() => { dispatch({ type: HIDE_LOADING_MODAL }); }}
        onBackdropPress={() => { dispatch({ type: HIDE_AUTH_MODAL }) }}
        style={{ zIndex: 5000000, minWidth: "60%" }}
      >
        <Animatable.View ref={wholeRef} style={{ minWidth: "40%", flex: 1, alignSelf: 'center', justifyContent: 'center' }}>
          <BlurView
            style={styles.blurViewStyle}
            pointerEvents='none'
          />
          <Animatable.View ref={modalRef} style={[styles.modalContainerAuth, { minWidth: 150, backgroundColor: theme ? 'white' : 'grey', padding: 50, width: '85%', zIndex: 99999 }]}>
            <View style={[styles.centerEverything, { flex: 3, minWidth: 150, alignSelf: "center", alignContent: "center" }]}>
          {
                messages &&
            returnTypeIcon()
          }
            </View>
            <View style={{ flex: 6, gap: 15, paddingVertical: 50 }} >
            {
                authModal.modalType === 'warning' || 'error' &&
                <>
                  <Text adjustsFontSizeToFit style={{ flex: 3, color: 'black', textAlign: 'center', lineHeight: 30, fontWeight: 'bold', flexWrap: 'wrap' }}>{messages}</Text>
                  {
                    details && details != undefined ?
                      <View style={{ gap: 10, flex: 6 }}>
                <Pressable onPress={() => setShowDetails(!showDetails)} style={{ flex: 1, gap: 10, maxHeight: 35 }}>
                  <Text style={{ opacity: 0.7, fontStyle: 'italic', textAlign: 'center' }}>Show details?</Text>
                </Pressable>
                {
                          showDetails &&
                  <View style={{ flex: 1, borderRadius: 40, backgroundColor: '#ececec', maxWidth: 350, padding: 30, gap: 20 }}>
                            <Text style={{ flex: 2.5, overflow: 'scroll' }}>{JSON.stringify(details ? details : "No details available for this error")}</Text>
                            <Button icon={'flag'} customStyle={{ top: 20 }} onPress={() => console.log('handle flag error!')} buttonText="Flag" />
                          </View>
                        }
                      </View>
                      :
                      undefined
                  }
                </>
            }
          </View>
            <Button customStyle={{ flex: 1, minHeight: 40, maxHeight: 40, top: 15, minWidth: 200, width: 200 }} onPress={() => { dispatch({ type: HIDE_AUTH_MODAL }) }} buttonText="Close" />
          </Animatable.View>
        </Animatable.View>
      </Modal >
    )
};

export default AuthModal