import { useState } from 'react';
import { View, Text, Image } from 'react-native';
import Modal from 'react-native-modal';
import { Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { HIDE_USER_CONTEXT_MODAL } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import styles from '@stylesheet';
import React from 'react';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';

const MessageSettings = ({ ...props }) => {
  const isVisible = useSelector((state: DefaultRootState) => state.modals.messageModal.userSettingsModal);
  const selectedProfile = useSelector((state: DefaultRootState) => state.modals.messageModal.userSettingsContent);
  const [buttonHovered, setButtonHovered] = useState(false);
  const dispatch = useDispatch();
  const theme = themeSelector();

  return (
    <Modal
      animationIn={'bounceInDown'}
      animationInTiming={1000}
      animationOut={'slideOutUp'}
      isVisible={isVisible}
      coverScreen={false}
      hasBackdrop={true}
      backdropOpacity={.6}
      style={{ flex: 1, flexDirection: 'row' }} >
      <View style={{ backgroundColor: '#ECECEC', borderRadius: 20, flexBasis: 'auto', elevation: 1, shadowRadius: 10, flex: 1, flexDirection: 'column', minWidth: 225, padding: 15 }}>
        <View style={{ flex: 1 }}>
          <Image source={selectedProfile ? selectedProfile.avatar : null} style={[styles.profileAvatar, { backgroundColor: theme ? 'white' : 'black' }]} />
        </View>
        <View style={{ flex: 1 }} />
        <Text adjustsFontSizeToFit style={{ color: 'black', fontSize: 24 }}>{selectedProfile ? selectedProfile.name + ' ' + selectedProfile.surname : null}</Text>
        <View style={{ flex: 1 }} />
        <Text adjustsFontSizeToFit style={{ color: 'black', fontSize: 24 }}>{selectedProfile ? selectedProfile.department : null}</Text>
        <Text adjustsFontSizeToFit style={{ color: 'black', fontSize: 24 }}>{selectedProfile ? selectedProfile.role : null}</Text>
        <Pressable
          onPress={() => { dispatch({ type: HIDE_USER_CONTEXT_MODAL }) }}
          style={[styles.LoginButton, { alignSelf: 'flex-end', flex: 1 }]}
          onHoverIn={() => setButtonHovered(true)}
          onHoverOut={() => setButtonHovered(false)}
        >
          <Text adjustsFontSizeToFit style={styles.appButtonText}>Back</Text>
        </Pressable>
      </View>
    </Modal>
  )
}

export default MessageSettings; 