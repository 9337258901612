import React, { useRef } from 'react';
import { Animated, Text, View, StyleSheet, ScrollView } from 'react-native';
import { useSelector } from "react-redux";
import { DefaultRootState } from "@reduxLocal/persistState";
import { DataTable } from 'react-native-paper';
import { useLottieAnim } from '@hooks/lottieHook';
import LottieView from 'lottie-react-native';
import { SubscriptionInfo } from '../modals/RegisterModal/Components/SubscriptionInfo';

const DynamicPlanInfo = ({ selectedPlan, verticalTable }) => {
  const animation = useLottieAnim();
  const planIndex = useSelector((state: DefaultRootState) => state.modals.registerModal.selectedPlan);
  const SubInfo = SubscriptionInfo;

  const plan = selectedPlan ? selectedPlan.metadata : SubInfo[planIndex?.plan];

  return (
    <View style={{ flex: 1, alignSelf: "stretch", width: "100%" }}>
      <DataTable style={{ flex: 1, borderColor: "rgb(90,192,234)", maxHeight: 100, top: -20 }}>
        <DataTable.Header style={{ borderBottomWidth: 2, borderColor: "rgb(90,192,234)" }}>
          {
            !selectedPlan &&
            <DataTable.Title>Plan</DataTable.Title>
          }
          <DataTable.Title>Data Models</DataTable.Title>
          <DataTable.Title>Max Users</DataTable.Title>
          <DataTable.Title >Storage Limit</DataTable.Title>
          </DataTable.Header>
          <DataTable.Row style={{ borderBottomWidth: 0 }}>
          {
            !selectedPlan &&
            <DataTable.Cell>{plan?.plan}</DataTable.Cell>
          }
          <DataTable.Cell>{selectedPlan ? (selectedPlan.metadata?.data_limit <= 0 ? "Unlimited" : selectedPlan.metadata?.data_limit) : plan?.plan}</DataTable.Cell>
          <DataTable.Cell>{selectedPlan ? selectedPlan.metadata?.max_users : plan?.users}</DataTable.Cell>
          <DataTable.Cell >{selectedPlan ? selectedPlan.metadata?.storage_limt : plan?.storage}GB</DataTable.Cell>
        </DataTable.Row>
      </DataTable>
      <ScrollView style={{ flex: 10, top: 20 }}>
        {
          verticalTable ?
            <DataTable style={{ flex: 1, marginVertical: 20 }}>
              <DataTable.Row style={tableStyleSheet.rowStyle}>
                <DataTable.Cell>Basic Support</DataTable.Cell>
                <DataTable.Cell>{plan?.features[0] ? <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation2.json')} autoPlay></LottieView> : <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation.json')} autoPlay></LottieView>}</DataTable.Cell>
              </DataTable.Row>
              <DataTable.Row style={tableStyleSheet.rowStyle}>
                <DataTable.Cell>Basic ML and AI</DataTable.Cell>
                <DataTable.Cell>{plan?.features[1] ? <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation2.json')} autoPlay></LottieView> : <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation.json')} autoPlay></LottieView>}</DataTable.Cell>
              </DataTable.Row>
              <DataTable.Row style={tableStyleSheet.rowStyle}>
                <DataTable.Cell>Limited API Integration</DataTable.Cell>
                <DataTable.Cell>{plan?.features[2] ? <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation2.json')} autoPlay></LottieView> : <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation.json')} autoPlay></LottieView>}</DataTable.Cell>
              </DataTable.Row>
              <DataTable.Row style={tableStyleSheet.rowStyle}>
                <DataTable.Cell>Internal Messaging</DataTable.Cell>
                <DataTable.Cell>{plan?.features[3] ? <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation2.json')} autoPlay></LottieView> : <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation.json')} autoPlay></LottieView>}</DataTable.Cell>
              </DataTable.Row>
              <DataTable.Row style={tableStyleSheet.rowStyle}>
                <DataTable.Cell>External Messaging</DataTable.Cell>
                <DataTable.Cell>{plan?.features[4] ? <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation2.json')} autoPlay></LottieView> : <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation.json')} autoPlay></LottieView>}</DataTable.Cell>
              </DataTable.Row>
              <DataTable.Row style={tableStyleSheet.rowStyle}>
                <DataTable.Cell>Business Networking</DataTable.Cell>
                <DataTable.Cell>{plan?.features[5] ? <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation2.json')} autoPlay></LottieView> : <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation.json')} autoPlay></LottieView>}</DataTable.Cell>
              </DataTable.Row>
              <DataTable.Row style={tableStyleSheet.rowStyle}>
                <DataTable.Cell>Extended API</DataTable.Cell>
                <DataTable.Cell>{plan?.features[6] ? <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation2.json')} autoPlay></LottieView> : <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation.json')} autoPlay></LottieView>}</DataTable.Cell>
              </DataTable.Row>
              <DataTable.Row style={tableStyleSheet.rowStyle}>
                <DataTable.Cell>24/7 Support</DataTable.Cell>
                <DataTable.Cell>{plan?.features[7] ? <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation2.json')} autoPlay></LottieView> : <LottieView style={tableStyleSheet.animationStyle} ref={animation} source={require('../../../assets/animations/featureAnimation.json')} autoPlay></LottieView>}</DataTable.Cell>
              </DataTable.Row>
            </DataTable>
            :
        <DataTable style={{ flex: 1, marginVertical: 20 }}>
          <DataTable.Header style={{ borderBottomWidth: 2 }}>
            <DataTable.Title style={{ flex: 1 }}>Basic Support</DataTable.Title>
            <DataTable.Title style={{ flex: 1 }}>Basic ML and AI</DataTable.Title>
            <DataTable.Title style={{ flex: 1 }}>Limited API Integration</DataTable.Title>
            <DataTable.Title style={{ flex: 1 }}>Internal Messaging</DataTable.Title>
            <DataTable.Title style={{ flex: 1 }}>External Messaging</DataTable.Title>
            <DataTable.Title style={{ flex: 1 }}>Business Networking</DataTable.Title>
            <DataTable.Title style={{ flex: 1 }}>Extended API</DataTable.Title>
            <DataTable.Title style={{ flex: 1 }}>24/7 Support</DataTable.Title>
              </DataTable.Header>
          <DataTable.Row style={{ borderBottomWidth: 0 }}>
            {
                  plan?.features.map((feature) => {
                if (feature) {
                  return (
                    <DataTable.Cell disabled><LottieView ref={animation} source={require('../../../assets/animations/featureAnimation2.json')} autoPlay></LottieView></DataTable.Cell>
                  )
                } else return <DataTable.Cell disabled><LottieView ref={animation} source={require('../../../assets/animations/featureAnimation.json')} autoPlay></LottieView></DataTable.Cell>
              })
            }
          </DataTable.Row>
        </DataTable>
        }
      </ScrollView>
      <View style={{ flexDirection: 'row', alignSelf: 'stretch', maxHeight: 70, marginHorizontal: 10 }}>
        <View style={{ flexDirection: 'column', flex: 1 }}>
          <Text adjustsFontSizeToFit style={{ marginVertical: 20, textAlign: "center", fontWeight: "bold" }}>Monthly Price</Text>
          <View style={{ borderRadius: 20, minHeight: 60, elevation: 1, shadowRadius: 5, shadowOpacity: 0.4, shadowOffset: { width: 4, height: 4 }, flex: 1, padding: 8, marginHorizontal: 10, backgroundColor: '#58BFEA', }}>
            <Text adjustsFontSizeToFit style={{ textAlign: "center", fontSize: 30, color: 'white' }}>{selectedPlan?.default_price?.unit_amount}</Text>
          </View>
        </View>
        <View style={{ flexDirection: 'column', flex: 1 }}>
          <Text adjustsFontSizeToFit style={{ marginVertical: 20, textAlign: "center", fontWeight: "bold" }}>Annual Price</Text>
          <View style={{ borderRadius: 20, minHeight: 60, elevation: 1, shadowRadius: 5, shadowOpacity: 0.4, shadowOffset: { width: 4, height: 4 }, flex: 1, padding: 8, marginHorizontal: 10, backgroundColor: '#58BFEA' }}>
            <Text adjustsFontSizeToFit style={{ textAlign: "center", fontSize: 30, color: 'white' }}>{selectedPlan?.default_price?.unit_amount}</Text>
          </View>
        </View>
      </View>
    </View>
  )
}

const tableStyleSheet = StyleSheet.create({
  rowStyle: {
    flex: 1,
    borderBottomWidth: 0
  },
  animationStyle: {
    flex: 1,
    maxHeight: 50
  }
});
export default DynamicPlanInfo;