import React, { useState, useCallback, useEffect } from 'react';
import { View, Text, Image, Pressable, Platform } from 'react-native';
import MapView, { Circle, Marker } from 'react-native-maps';
import LottieView from 'lottie-react-native';
import { LinearGradient } from 'expo-linear-gradient';
import styles from '@stylesheet';
import * as Animatable from 'react-native-animatable';
import { AntDesign } from '@expo/vector-icons';
import * as Location from 'expo-location';
import circleOptions from '../../../navigation/screens/MidLevel/NetworkingScreen/utils/circleOptions';
import MarkerComponent from '../../../navigation/screens/MidLevel/NetworkingScreen/components/MarkerComponent';

const MapComponent = ({ providedLocation }) => {

  let center = {
    latitude: 51.496600,
    longitude: -0.381150,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  }

  let user = {
    id: 5,
    profile_information: {
      name: 'User 5',
      surname: 'HI',
      avatar: 'https://storage.googleapis.com/smartsaas-avatar-store/Asset%202.png',
      achievementPoints: 80,
    },
    achievements: { achievementPoints: 100 },
    address: '222 Square Ln',
    location: {
      coords: {
        accuracy: 10.0,
        altitude: 35.0,
        altitudeAccuracy: 23.0,
        heading: -1,
        latitude: 51.5,
        longitude: -0.4,
        speed: -1,
      },
      timestamp: 1702679316281.9773,
    },
    visible: true,
    private: false,
    store: false,
  };
  
  return (
    <MapView
      initialRegion={center}
      region={center}
      style={{ flex: 1, margin: -10, overflow:"hidden" }}
    >
      <Circle style={circleOptions} center={{
        latitude: user.location?.coords.latitude,
        longitude: user.location?.coords.longitude
      }} radius={20} fillColor='#5ac0ea22' strokeColor='#5ac0ea' strokeWidth={5} />
      <Marker key={'bcardProfile'} coordinate={{
        latitude: user.location.coords.latitude,
        longitude: user.location.coords.longitude,
      }}>
        <MarkerComponent zoomLevel={undefined} userData={user} />
      </Marker>
    </MapView>
  )
}
export default MapComponent;