import React, { useState } from 'react';
import { Modal } from '../../../constants/Themed';
import { Icon } from 'react-native-paper';
import { View, Image, Pressable } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import Packages from './SubComponents/Packages';

const PackageModal = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  return (
    <Modal
      isVisible={open}
      style={{ minWidth: "53%", top: -50, minHeight: "80%" }}
      onBackdropPress={() => setOpen(false)}
    >
      <Pressable onPress={() => setOpen(false)} style={{ zIndex: 1, position: 'absolute', top: -18, right: 10, borderRadius: 40 }}>
        <AntDesign name="closecircle" size={40} color="red" />
      </Pressable>
      <View style={{ flexDirection: "row", marginHorizontal: -20 }}>
        {
          open &&
        <Packages open={open} />
        }
      </View>
    </Modal>
  )
}
export default PackageModal;