import { View, Dimensions, Image, Platform, Pressable } from 'react-native';
import { useEffect, useRef, useState } from 'react';
import Carousel from 'react-native-reanimated-carousel';
import { useTranslation } from 'react-i18next';
import { CloudText } from '@assets/--native';
import { useQuery } from 'react-query';
import apis from '@api/api';
import LottieView from 'lottie-react-native';
import React from 'react';
import { Title, Text } from '@constants/Themed';
import * as Animatable from 'react-native-animatable';
import * as WebBrowser from 'expo-web-browser';

const DynamicContentInfo = () => {
  const { t } = useTranslation();
  const [carouselData, setCarouselData] = useState();
  const loadingRef = useRef<Lottie>(null);

  useEffect(() => {
    loadingRef.current?.play(0, 200);
  }, [])

  const { status, data, error, isFetching, isLoading, refetch } = useQuery({
    queryKey: ['data'],
    queryFn: async () => {
      const data = await apis.getLoginMessages()
        .then(res => {
          return res
        }).catch(e => {
          console.log(e);
        });
      setCarouselData(data);
      return data;
    },
    retry: 2
  })

  useEffect(() => {
    // Function to ping the server
    const pingServer = async () => {
      try {
        if (error)
          refetch()
      } catch (e) {
        // Error occurred while pinging the server
        console.error('Error pinging the server', e);
      }
    };

    // Initial play animation
    loadingRef.current?.play(0, 200);

    // Periodically ping the server every minute
    const pingInterval = setInterval(() => {
      pingServer();
    }, 60000);
    if (error) {
      console.log(error);
    }
    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(pingInterval);
  }, [error]); 

  function emailUs() {
    WebBrowser.openBrowserAsync(
      'https://www.smartsaas.co.uk/privacy-policy/'
    )
  }

  const width = Dimensions.get('window').width;
  const [mode, setMode] = useState<any>('horizontal-stack');
  const [snapDirection, setSnapDirection] = useState<'left' | 'right'>(
    'right'
  );
  const [pagingEnabled, setPagingEnabled] = useState<boolean>(true);
  const [snapEnabled, setSnapEnabled] = useState<boolean>(true);
  const [loop, setLoop] = useState<boolean>(true);
  const [autoPlay, setAutoPlay] = useState<boolean>(false);
  const [autoPlayReverse, setAutoPlayReverse] =
    useState<boolean>(false);
  const viewCount = 0;



  return (
    <View style={{ overflow: 'visible' }}>
      {
        error ?
          <Animatable.View animation={Platform.OS === 'web' ? 'slideInDown' : 'slideInUp'} style={{ flex: 1, padding: 15, marginBottom: -40, minWidth: 300, minHeight: 160, maxHeight: 250, borderRadius: 20, backgroundColor: 'white', paddingHorizontal: 15, flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
            <LottieView autoPlay style={{ alignSelf: 'center', justifyContent: 'center', flex: 1, padding: 5, maxWidth: 80 }} source={require('@assets/animations/errorIcon.json')} loop={true} speed={1} />
            <View style={{ flexDirection: "row-reverse", flex: 1, paddingHorizontal: 30 }}>
              <View style={{ flex: 10, minHeight: '100%', gap: 5 }}>
                <Title adjustsFontSizeToFit style={{ fontWeight: "bold", lineHeight: 20, marginBottom: 5, fontSize: 16 }}>Our server may be offline</Title>
                <Pressable onPress={() => emailUs()}><Text adjustsFontSizeToFit style={{ fontSize: 20 }} numberOfLines={4}>We apologise for any inconvenience, if this is the first time this error has appeared, try {Platform.OS === 'web' ? "refreshing the page" : "force closing and re-opening the app"}.</Text></Pressable>
              </View>
            </View>
          </Animatable.View>
          :
          <Carousel
            loop
            width={width * 0.4}
            height={Platform.OS != 'web' ? width / 2 : 110}
            autoPlay={true}
            data={carouselData}
            scrollAnimationDuration={3000}
            pagingEnabled={pagingEnabled}
            snapEnabled={snapEnabled}
            style={{ flex: 1, overflow: 'visible', margin: 25, top: -20, justifyContent: 'center', paddingLeft: 50 }}
            mode={'horizontal-stack'}
            modeConfig={{
              snapDirection,
              stackInterval: mode === 'vertical-stack' ? 8 : 18,
            }}
            renderItem={({ index, item }) => (
              <View
                style={{
                  justifyContent: 'center',
                  padding: 20,
                  shadowRadius: 20, shadowOpacity: .05,
                  borderRadius: 20,
                  minWidth: 350,
                  overflow: 'visible',
                  backgroundColor: 'white'
                }}
              >
                <Text adjustsFontSizeToFit style={{ marginBottom: -5, fontSize: 12, fontWeight: '600' }}>{t(item.title)}</Text>
                <View style={{ flex: 0.1, minHeight: 10 }} />
                <Text adjustsFontSizeToFit style={{ fontSize: 10, lineHeight: 20, paddingRight: 40 }}>{t(item.descriptor)}</Text>
              </View>
            )}
          />
      }
    </View>
  )
}
export default DynamicContentInfo;