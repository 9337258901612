import { FlatList } from "react-native";
import React, { useEffect, useState, useCallback } from "react";
import { Text, View, Pressable } from 'react-native';
import topics from "./topicMap";
import toTitleCase from '@utils/toTitleCase';
import * as Animatable from 'react-native-animatable';
import striptags from 'striptags';
import moment from 'moment';
import { useQuery } from "react-query";
import apis from "@api/api";
import { filter } from "lodash";

type HelpSearchTypes = {
  searchTerm: string,
  handleFlip: Function
}

const ShowHelp = ({ searchTerm, handleFlip }: HelpSearchTypes) => {
  const [data, setData] = useState([]);
  const [topicList, setTopics] = useState(topics);
  const [filteredData, setFilteredData] = useState([]);
  const [topicSelected, setTopicSelected] = useState({ id: null, name: null });

  //Retrieves common help articles from smartsaas.co.uk
  const handleGetData = async () => {
    await fetch('https://smartsaas.co.uk/wp-json/wp/v2/docs', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }).then(async res => {
      await res.json()
        .then(
          data => {
            setData(data)
            setFilteredData(data)
          }
        )
        .catch(err => { console.error(err); setData([]) })

    })
  }

  console.log(data, 'data list');

  const { status, error, isFetching, isLoading, refetch } = useQuery({
    queryKey: ['helpInternalKnowledge'],
    queryFn: async () => {
      const data = await apis.getInternalKnowledge();
      if (data) {
        //Check if topics 
        if (!topicList.includes({ name: "internal", id: 0, icon: null })) {

          setTopics(oldList => {
            return (
              [{ name: "internal", id: 0, icon: null }, ...oldList]
            )
          })
        }
        setData(data => [data.internal, ...data])
        //Push the data array to the beggining of the array so it shows up as the first topic
        return data.internal
      }
    },
    retry: 0,
  });

  function findClosestTopic(topic) {
    const topicArray = topics.map(item => {
      if (item.name.includes(topic)) {
        return item
      } else return
    })
    return topicArray[0].id
  }

  const filterByTopic = useCallback(
    (topic) => {
      // if (typeof topic === 'string') {
      //   const newTopic = { name: topic, id: findClosestTopic(topic) }
      //   console.log(newTopic)
      //   setTopicSelected(newTopic)
      //   const filtered = data.filter((item) => {
      //     console.log(item.doc_category);
      //     return item.doc_category.some((category) => {
      //       console.log(category, 'is the category');
      //       return category === topic.id
      //     }
      //     );
      //   });
      //   setFilteredData(filtered);
      // }
      if (topicSelected && topicSelected?.id === topic.id) {
        setTopicSelected({ id: null, name: null });
        setFilteredData(data); // Reset the filter
      } else {
        setTopicSelected(topic);
        // Filter data based on the selected topic
        const filtered = data.filter((item) => {
          console.log(item?.doc_category);
          return item?.doc_category?.some((category) => {
            console.log(category, 'is the category');
            return category === topic.id
          }
          );
        });
        setFilteredData(filtered);
      }
    },
    [data, topicSelected]
  );



  useEffect(() => {
    handleGetData()
    if (searchTerm) {
      filterByTopic(searchTerm)
    }
  }, [searchTerm])

  const _renderItem = ({ item, index }) => {
    if(!item) return null;
    return (
      <Animatable.View animation={'slideInLeft'} delay={index * 100} >
        <Pressable style={{ padding: 10, flexDirection: 'row', backgroundColor: topicSelected?.id === item?.id ? 'rgb(90,192,234)' : 'white', alignContent: 'space-around', gap: 5 }} onPress={() => handleFlip(item)}>
          <View style={{ flex: 3 }}>
            <Text>{typeof item?.title === 'string' ? item?.title : item.title?.rendered}</Text>
            <Text numberOfLines={2} style={{ color: 'grey', fontStyle: 'italic', }}>{striptags(item.excerpt?.rendered)}</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={{ textAlign: 'right', fontStyle: 'italic' }}>Updated:</Text>
            <Text style={{ textAlign: 'right' }}>{moment(item?.modified).format('ddd DD MM yyyy')}</Text>
          </View>
        </Pressable>
      </Animatable.View>
    )
  }


  const _renderTopics = ({ item }) => {
    const { name, id } = item;
    return (
      <Pressable onPress={() => filterByTopic({ name, id })} style={{ paddingVertical: 5, flex: 1, gap: 5, width: 100, borderRadius: 10, shadowRadius: 5, shadowOpacity: 0.2, elevation: 10, backgroundColor: topicSelected.id === item?.id ? 'rgb(90,192,234)' : '#ececec', maxHeight: topicSelected ? 30 : 30 }}>
        {!topicSelected &&
          item?.icon
        }
        <Text style={{ textAlign: 'center', fontWeight: 'bold', verticalAlign: "middle" }}>{toTitleCase(item.name)}</Text>
      </Pressable>
    )
  }

  return (
    <View style={{ flex: 1, alignSelf: "stretch", minHeight: "100%" }}>
      <FlatList style={{ flex: 2, flexGrow: 5, flexShrink: 1, maxHeight: 80, height: topicSelected ? 50 : 100 }} data={topicList} renderItem={_renderTopics} horizontal contentContainerStyle={{ gap: 20, padding: 10 }} />
      <FlatList style={{ flex: 5, flexGrow: 10, minHeight:"80%" }} data={filteredData} renderItem={_renderItem} contentContainerStyle={{ gap: 10 }} />
    </View>
  )
}
export default ShowHelp;