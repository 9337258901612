import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View, TextInput, TouchableOpacity, Text, FlatList, Pressable } from 'react-native';
import { FontAwesome5, MaterialIcons } from '@expo/vector-icons';
import * as Animatable from 'react-native-animatable';
import { InteractiveSearchTypes } from '../Types';
import searchStyles from '../searchStyles';
import { Checkbox } from 'react-native-paper';
import parseByDelimiter from '@utils/parseByDelimiter';
import { debounce, set } from "lodash";
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import AnimatedLottieView from 'lottie-react-native';
import FontAwesome from '@expo/vector-icons/FontAwesome';
import LoadingIndicator from '@components/Global/Spinner';
const AnimatedIcon = ({ name, theme }) => (
  <Animatable.View animation="fadeInDown" duration={250} style={[searchStyles.iconStyle, { overflow: "hidden" }]}>
    <FontAwesome5 name={name} size={name === 'ellipsis-h' ? 24 : 20} color={!theme ? "rgba(0,0,0,0.5)" : "grey"} style={searchStyles.searchIconStyle} />
  </Animatable.View>
);


const InteractiveSearch = ({ aiCallback, aiSearch, placeHolder, searchParams, callback }: InteractiveSearchTypes) => {
  const [focusedState, setFocusedState] = useState(false);
  const [text, setText] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterArray, setFilterArray] = useState([]);
  const searchRef = useRef(null);
  const theme = themeSelector();
  const [AILoading, setAILoading] = useState(false);
  const [searchParamArray, setSearchParamArray] = useState(searchParams);

  const clearSearch = useCallback(() => {
    handler("");
    setText("");
  }, [text])


  const handleCallback = (text) => { callback?.(text) };

  const handler = debounce(handleCallback, 500)

  const RowItem = ({ item, index }) => {
    const [checked, setChecked] = useState(false);

    const handleSelect = useCallback((checked, item) => {
      if (checked) {
        setFilterArray(value => [...value, item.dataTitle])
      }
    }, [])

    return (
      <View style={{ flexDirection: 'row' }}>
        <Checkbox status={checked ? "checked" : "unchecked"} onPress={() => { setChecked(!checked); handleSelect(checked) }} />
        <Text style={{ justifyContent: 'center', verticalAlign: 'middle' }}>{parseByDelimiter(item.dataTitle)}</Text>
      </View>
    )
  }

  useEffect(() => {
    if (filterArray.length > 0) {
      let newArray = [];
      searchParams.forEach(item => {
        if (filterArray.includes(item.id)) {
          newArray.push(item.dataSchema)
        }
      }
      )
    }
  }, [
    filterArray
  ])


  return (
    <View style={{ flexDirection: 'row', top: -3, flexBasis: 120, flexShrink: 1, flexGrow: 20, maxHeight: 40 }}>
      <View style={{ flexBasis: 20, flexShrink: 5, top: 10, maxWidth: 60 }}>
        {focusedState ? <AnimatedIcon theme={theme} name="ellipsis-h" /> : (aiSearch ? <AnimatedLottieView source={require("@assets/animations/automation_robot.json")} autoPlay loop style={{ left: -25, top: -15, height: 50, width: 50 }} /> : <AnimatedIcon name="search" theme={theme} />)}
      </View>
      {
        filterVisible ?
          <View style={{ position: 'absolute', minWidth: 300, minHeight: 300, top: 50, left: 100, backgroundColor: 'rgb(90,192,234)', borderRadius: 20, overflow: 'visible' }}>
            <FlatList data={searchParams} style={{ flex: 1 }} renderItem={({ item, index }) => { return (<RowItem item={item} index={index} />) }} />
          </View>
          : null
      }
      {
        filterArray.length > 0 &&
        <View style={{ flexDirection: 'column' }}>
          {
            filterArray.map((item, index) => {
              return (
                <View style={{ backgroundColor: 'rgb(90,192,234)' }}>
                  <Text>
                    {item}
                  </Text>
                </View>
              )
            })

          }
        </View>
      }
      <TextInput
        ref={searchRef}
        id="search"
        onFocus={() => setFocusedState(true)}
        onBlur={() => { setFocusedState(false) }}
        style={[searchStyles.inputStyle, {  flexBasis: 1, maxWidth: 600, backgroundColor: theme ? 'white' : 'grey' }]}
        value={text}
        onChangeText={(text) => {
          if (text.includes('/')) {
            setFilterVisible(true);
          }
          setText(text);
          handler(text); // Use the debounced callback here
        }}
        placeholder={placeHolder || 'Search'}
        placeholderTextColor={theme ? "grey" : "white"}
      />
      <Pressable
        style={{
          opacity: text !== '' ? 1 : 0.2,
          justifyContent: 'center',
          width: 20,
          zIndex: 12,
          minWidth: 20
        }}
        disabled={text === ''}
        hitSlop={10}
        onPress={clearSearch}
      >
        <MaterialIcons
          name="cancel"
          size={24}
          style={{ overflow: 'visible', minWidth: 50 }}
          color={text !== '' ? 'black' : 'rgba(0,0,0,0.2)'}
        />
      </Pressable>
      {
        aiSearch &&
        <Pressable onPress={async () => {
          setAILoading(true)
          await aiCallback?.(text).then((res) => {
            setAILoading(false);
            return res
          }).catch(err => {
            console.error(err)
            setAILoading(false)
          })
        }} style={{ flex: 1, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
          {
            AILoading ?
              <LoadingIndicator type={undefined} color={"rgb(90,192,234)"}/>
              :
              <FontAwesome name="magic" size={24} color={theme ? "black" : "white"} />
          }
        </Pressable>
      }
    </View>
  );
};

export default InteractiveSearch;
