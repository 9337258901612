import React, { forwardRef, useContext, useEffect, useRef } from "react";
import { actions, RichToolbar } from "react-native-pell-rich-editor";
import { Text } from 'react-native';
import handleImageUpload from "@utils/handleCamera";
import { Platform } from "react-native";

interface editorProps {
  vertical?: boolean;
  backgroundColor?: string;
  style?: object;
  actions?: any[];
  iconTint?: string;
}

const ToolBarComponent = forwardRef((props: editorProps, ref) => {
  const { actions: customActions, iconTint } = props;
  const handleHead = ({ tintColor }) => <Text style={{ color: tintColor }}>H1</Text>
  const handleHead2 = ({ tintColor }) => <Text style={{ color: tintColor }}>H2</Text>
const handleHead3 = ({ tintColor }) => <Text style={{ color: tintColor, fontSize: 20 }}>f</Text>

  return (
    <RichToolbar
      editor={ref}
      vertical={props.vertical || false}
      flatContainerStyle={{gap: 10}}
      actions={customActions || [
        actions.undo,
        actions.setBold,
        actions.setItalic,
        actions.setUnderline,
        actions.fontSize,
        actions.fontName,
        actions.heading1,
        actions.heading2,
        actions.alignCenter,
        actions.insertBulletsList,
        actions.insertOrderedList,
        actions.insertLink,
        actions.code,
        actions.insertImage,
        actions.insertVideo,
        actions.checkboxList,
        actions.setStrikethrough,
      ]}
      iconTintColor={iconTint || "white"}
      iconMap={{ [actions.heading1]: handleHead, [actions.heading2]: handleHead2, [actions.fontName]: handleHead3 }}
      actionsConfig={{}}
      style={[{ flexDirection: 'column', backgroundColor: props.backgroundColor, paddingVertical: Platform.select({ default: 20, web: undefined })}, props.style]}
      onPressAddImage={() => handleImageUpload().then((image) => ref.current?.insertImage(image))}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    />
  )
});

export default ToolBarComponent;
