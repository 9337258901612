import { useSelector } from 'react-redux';
import {
	SHOW_AUTH_MODAL,
	SHOW_TC_MODAL,
	ACCEPT_TERMS,
	HIDE_TC_MODAL,
} from '@reduxLocal/actions/types';
import { DefaultRootState, store } from '@reduxLocal/persistState';

// Make sure to import your action types

function select(state) {
	return state.modals.tcModal;
}

function handleChange(resolve, reject) {
	return function () {
		const currentValue = select(store.getState());

		if(!currentValue.modalDisplayed){
			store.dispatch({ type: HIDE_TC_MODAL });
			setTimeout(() => {
				store.dispatch({
					type: SHOW_AUTH_MODAL,
					data: {
						content:
							'You must accept the terms and conditions to continue.',
						type: 'error',
					},
				});
			}, 1000);
			reject(new Error('You must accept the terms and conditions to continue.'));
		}

		if (currentValue.accepted) {
			console.log('Detected state change', currentValue);
			resolve(true); // Assuming 'resolve' is available in the scope
		}
	};
}

const getTCConfirm = async () => {
	// Create a promise that resolves after 10 minutes (600,000 milliseconds)
	const timeoutPromise = new Promise((_, reject) => {
		setTimeout(() => {
			store.dispatch({ type: HIDE_TC_MODAL });
			reject(new Error('Timeout: The operation took too long.'));
		}, 600000); // 10 minutes
	});

	return Promise.race([
		timeoutPromise, // Wait for the 10-minute timeout
		new Promise(async (resolve, reject) => {
			try {

				const unsubscribe = store.subscribe(handleChange(resolve, reject));
				// You may want to unsubscribe when the condition is met or when you're done listening.
				// For now, it's left unsubscribed for demonstration purposes.
				// return unsubscribe;
			} catch (e) {
				reject(e); // Reject the promise if there's an error
			}
		}),
	]);
};

export default getTCConfirm;
