import React, { useState, useCallback } from 'react';
import { View, Text, Pressable, Platform, ScrollView } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import HorizontalCarousel from '@components/Global/Carousel/HorizontalCarousel';
import { Entypo, FontAwesome } from '@expo/vector-icons';
import { Title } from '@constants/Themed';
import AchievementBackground from '@assets/--native/AchievementBackground';
import { ADD_BASKET_ITEM, CLEAR_BASKET, REMOVE_BASKET_ITEM } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import { useDispatch, useSelector } from 'react-redux';
import styles from '@stylesheet';
import * as Animatable from 'react-native-animatable';
import MaskedView from '@react-native-masked-view/masked-view';
import apis from '@api/api';

const ProductItem = ({ item, index, type, interaction = false }) => {
  const basketItems = useSelector((state: DefaultRootState) => state.store.basketItems);
  const [fullView, setFullView] = useState(false); 
  const dispatch = useDispatch();

  const getDescription = () => {
    //Find any key that contains the word description
    const descriptionKey = Object.keys(item.data).filter(key => key.toLowerCase().includes('description'));
    if (descriptionKey.length > 0) {
      return item.data[descriptionKey[0]];
    }
    else {
      return item.data.product_description || item.data.description || item.description || item.data.product_description || item.data.description || item.data.product_description || item.data.description;
    }
  }

  const _handleAddBasket = useCallback((item) => {
    dispatch({ type: ADD_BASKET_ITEM, payload: item })
  }, []);

  const _removeItemFromBasket = () => {
    console.log(item);
    dispatch({ type: REMOVE_BASKET_ITEM, payload: item })
  }

  const [quantity, setQuantity] = useState(0);

  const checkQuantity = () => {
    const itemInBasket = basketItems.filter(basketItem => basketItem._id === item._id);
    if (itemInBasket.length > 0) {
      return itemInBasket[0].basketQuantity;
    }
    else {
      return 0;
    }
  }
  return (
    <Animatable.View transition={'height'} style={{ backgroundColor: '#ececec', flex: 1, margin: Platform.OS === 'web' ? 20 : undefined, maxWidth: 300, borderRadius: 30, height: fullView ? 550 : 380, padding: 20, shadowRadius: 20, shadowOpacity: .2, overflow: 'hidden' }}>
      <AchievementBackground style={{ position: 'absolute', overflow: 'hidden', opacity: 1, zIndex: -1, borderRadius: 20, transform: [{ scale: 4 }] }} />
      <View style={{ flex: 5, flexDirection: 'column' }}>
        <Pressable disabled={!interaction} onPress={async () => {
          const res = await handleImage();
          await apis.addDataImage(dataId, item._id, res);
        }} style={{ zIndex: 10, flex: 5, margin: -20, top: -55, maxHeight: fullView ? (Platform.OS === 'web' ? "40%" : "60%") : "100%", minHeight: 250, maxWidth: Platform.OS === 'web' ? 450 : undefined }}>
          <HorizontalCarousel removeSampleMark showArrows={false} images={item.dataImages} width={200} imageStyle={{ resizeMode: 'contain' }} height={300} />
        </Pressable>
        {fullView &&
          <ScrollView style={{ flex: 2.5 }}>
            <Title>Description</Title>
            <Text style={{ color: "white" }}>{getDescription()}</Text>
          </ScrollView>
        }
        <View style={{ borderRadius: 20, minHeight: 30, flex: 2.8, paddingHorizontal: 10, paddingVertical: 4 }}>
          <View style={{ flex: 1, backgroundColor: "#00000020", padding: 20, marginHorizontal: -30 }}>
            <View style={{ flexDirection: 'row', flex: 1, minHeight: 60 }}>
              <Title style={{ color: "white", flex: 1 }}>{item.data.product_name || item.data.event_name}</Title>
              <Title style={{ color: "white", flex: 1, textAlign: 'right' }}>£ {parseFloat(item.data.price || item.data.RRP || item.data.ticket_price || 0).toFixed(2)}</Title>
            </View>
            <View style={[styles.centerEverything, { flex: 1, minHeight: 80}]}>
              <MaskedView
                maskElement={
                  (
                    <View style={{ gap: 10, backgroundColor: "transparent", flexDirection: "row" }}>
                      <FontAwesome name="star" style={{ textAlign: "center" }} size={24} color="white" />
                      <FontAwesome name="star" style={{ textAlign: "center" }} size={24} color="white" />
                      <FontAwesome name="star" style={{ textAlign: "center" }} size={24} color="white" />
                      <FontAwesome name="star" style={{ textAlign: "center" }} size={24} color="white" />
                      <FontAwesome name="star" style={{ textAlign: "center" }} size={24} color="white" />
                    </View>
                  )
                }>
                <LinearGradient colors={['yellow', '#ececec20']} start={{ x: 0.5, y: 0.9 }} style={{ minHeight: '100%', minWidth: '100%', opacity: 0.8 }} />
              </MaskedView>
            </View>
          </View>
        </View>
      </View>
      <View style={{ flex: 1, alignSelf: 'stretch', marginHorizontal: -20, top: 20, position: 'relative', backgroundColor: '#ffffff99', shadowRadius: 20, shadowOpacity: .2, justifyContent: 'center', borderTopRightRadius: 0, overflow: 'hidden', borderBottomRightRadius: 20, borderBottomLeftRadius: 20, flexDirection: 'row', maxHeight: 40 }}>
        <Pressable style={[styles.centerEverything, { flex: 1, justifyContent: 'center', minHeight: 50 }]} onPress={(item) => { _removeItemFromBasket(item) }}>
          <Entypo name="squared-minus" size={24} color="white" />
        </Pressable>
        <View style={[styles.centerEverything, { flex: 1, maxHeight: 30 }]}>
          <Text style={{ color: "white", fontWeight: 'bold', fontSize: 30, textAlign: 'center', verticalAlign: 'middle', justifyContent: 'center' }}>{checkQuantity()}</Text>
        </View>
        <Pressable style={[styles.centerEverything, { flex: 1, justifyContent: 'center', minHeight: 50 }]} onPress={() => { _handleAddBasket(item) }}>
          <Entypo name="squared-plus" size={24} color="white" />
        </Pressable>
      </View>
    </Animatable.View>
  )
}

export default ProductItem;