import UserIcon from '@assets/icons/UserIcon';
import { DropDownPicker } from '@constants/Themed';
import { AntDesign } from '@expo/vector-icons';
import toTitleCase from '@hooks/toTitleCase';
import styles from '@stylesheet';
import React, { useEffect, useState } from 'react';
import { View, Text, Image, ImageStyle, ViewStyle } from 'react-native';
import * as Animatable from 'react-native-animatable';

interface UserListItemProps {
  item: string | { id: string, name: string, surname: string, email: string, avatar: string | { img: string } };
  index?: number;
  filterUser?: (index: number) => void;
  row?: boolean;
  removePermission?: boolean;
  textColor?: string;
  hideEmail?: boolean;
  avatarImageStyle?: ImageStyle;
  inverse?: boolean;
  hideDetails?: boolean;
  containerStyle?: ViewStyle;
}

const UserListItem = ({ containerStyle, hideDetails, inverse, avatarImageStyle, hideEmail = false, item, textColor, index, row, removePermission = false, filterUser }: UserListItemProps) => {
  const [userItem, setUserItem] = useState(item);

  if (!item || typeof item === 'boolean') return null;

  if (item === 'null' || item === 'localhost' || item === 'web'){
    return (
      <Animatable.View animation={'bounceIn'} style={{ flexDirection: row ? (inverse ? "column-reverse" : "column") : (inverse ? "row-reverse" : "row"), gap: 10, flex: 1, marginVertical: 10, ...containerStyle }}>
        <Image source={require('@assets/icons/cloud-connection.svg')} style={[styles.centerEverything, { aspectRatio: 1, borderColor: "white", width: 50, height: 50, borderRadius: avatarImageStyle?.width ? avatarImageStyle?.width / 2 : 50 }, avatarImageStyle]} />
      </Animatable.View>
    )
  }

    useEffect(() => {
      if (item.profile_information) {
        setUserItem(item.profile_information)
      }

    }, [item]);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [items, setItems] = useState([
    { label: 'Admin', value: 'admin' },
    { label: 'Common', value: 'common' }
  ]);

  return (
      <Animatable.View animation={'bounceIn'} style={{ flexDirection: row ? (inverse ? "column-reverse" : "column") : (inverse ? "row-reverse" : "row"), gap: 10, flex: 1, marginVertical: 10, ...containerStyle }}>
      {
        userItem.avatar && ((typeof userItem.avatar === 'string' && userItem.avatar.includes('http')) || (typeof userItem.avatar?.img === 'string' && userItem.avatar?.img.includes("http"))) ?
          <Image source={{ uri: typeof userItem.avatar === 'string' ? userItem.avatar : userItem.avatar?.img }} style={[styles.centerEverything, { aspectRatio: 1, borderColor: "white", width: 50, height: 50, borderRadius: avatarImageStyle?.width ? avatarImageStyle?.width / 2 : 50 }, avatarImageStyle]} />
          :
          <UserIcon style={[{ minHeight: 70 }, avatarImageStyle]} />
      }
        {
          !hideDetails &&
        <View style={{ flexDirection: "column", flex: 5, minWidth: "55%", top: 5 }}>
            <View style={{ flex: 1 }}>
              <Text style={{ flexWrap: 'wrap', textAlign: "center", color: textColor ? textColor : undefined, fontWeight: '400' }}>{toTitleCase(userItem.name) || `Inviting user to join your company: \n ${userItem?.email}`} {toTitleCase(userItem.surname)}</Text>
            </View>
              {
                !hideEmail &&
              <Text style={{ textAlign: "center", color: textColor ? textColor : undefined, fontWeight: '400' }}>{userItem.email}</Text>
              }
            </View>
        }
        {
          !removePermission &&
          <DropDownPicker
            open={open}
            style={[styles.dropdownInput, { maxHeight: 50, top: -20, maxWidth: 130 }]}
            dropDownContainerStyle={{ maxWidth: 120 }}
            closeAfterSelecting={true}
            placeholder="Role"
            setOpen={setOpen}
            items={items}
            value={value}
            setValue={setValue}
          />
        }
        {/* <AntDesign name="delete" size={24} color="black" onPress={() => { console.log(item); filterUser(item.id) }} /> */}
      </Animatable.View>
    )

}
export default UserListItem;