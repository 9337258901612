import React, { useEffect, useRef, useCallback } from 'react';
import { View, Pressable, FlatList, useWindowDimensions, ScrollView, Text } from 'react-native';
import { Image } from 'expo-image';
import { useDispatch, useSelector } from 'react-redux';
import styles from '@stylesheet';
import { PIN_NOTE, CLEAR_ALL_NOTES, SHOW_ADD_COMPANY_MODAL, NOTE_DROPPED, CLEAR_COMPANY, SWITCH_COMPANY } from '@reduxLocal/actions/types';
import { useState } from 'react';
import { Pressable as WebPressable } from 'react-native';
import { DefaultRootState, store } from '@reduxLocal/persistState';
import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import * as Animatable from 'react-native-animatable';
import { Title } from '@constants/Themed';
import { State } from 'react-native-gesture-handler'
import AnimatedD, {
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import toTitleCase from '@utils/toTitleCase';
import { getCompanyList } from '@api/api';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigation } from "@react-navigation/native";
import RenderNote from '../screens/MidLevel/WelcomeScreen/Widgets/Components/RenderNote';
import StatusBar from '@navigation/StatusBar/statusBar';
import SwipeableRobot, { RobotHandle } from '@components/RobotHelper/SwipeableRobot';
import { XStack } from 'tamagui';

const HeaderBar: any = ({ feedbackRef }) => {
  const authenticated = useSelector((state: DefaultRootState) => state.promiseData.isAuthenticated);
  const theme = useSelector((state: DefaultRootState) => state.app.theme.lightTheme);
  const visibleState = useSelector((state: DefaultRootState) => state.modals.notificationsModal.modalDisplayed);
  const companyInfo = useSelector((state: DefaultRootState) => state.promiseData.data?.companyData);
  const userDetails = useSelector((state: DefaultRootState) => state.promiseData.data?.userData);
  // let { planType } = useSelector((state: DefaultRootState) => state.promiseData.data?.userData);
  const stuckNotes = useSelector((state: DefaultRootState) => state.app.stickyNotes.pinnedNotes);
  const stickyNotes = useSelector((state: DefaultRootState) => state.app.stickyNotes);
  const userID = useSelector((state: DefaultRootState) => state.promiseData.data?.userData.id)
  const activeStickyNote = useSelector((state: DefaultRootState) => state.app.stickyNotes.activeNote);
  const addCompanyModalState = useSelector((state: DefaultRootState) => state.modals.formModal?.modalDisplayed);
  const menuOpen = useSelector((state: DefaultRootState) => state.UI.menuOpen);
  const textHelp = ['This feature allows you to manage your accounts and organisations. Simply create a new company and start adding users to boost start your business.', "Don't forget to add a logo to your shiny new company, that way it is easier to recognise when you are using it!", "Remember, a new company is a new subscription. You will be billed seperately for each company you create.", "You can switch between companies at any time by clicking on the company name in the top left corner of the screen.", "You can also add a new company by clicking on the plus icon next to the company name in the top left corner of the screen.", "You can also add a new company by clicking on the plus icon next to the company name in the top left corner of the screen."];
  const [text, setText] = useState(activeStickyNote?.content);
  const dispatch = useDispatch();
  const [hidden, setHidden] = useState(false);
  const [selectCompanyOpen, setSelectCompanyOpen] = useState(false);
  const [help, setHelp] = useState(true);
  const companiesData = useSelector((state: DefaultRootState) => state.promiseData.data?.userData?.companies);
  const [companyList, setCompanyList] = useState([]);
  const [xyValue, setXYValue] = useState(0);
  const translateX = useSharedValue(xyValue);
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentCompany = useSelector((state: DefaultRootState) => state.app.selectedProfile);
  const panRef = useRef();
  const queryClient = useQueryClient();
  const { width, height } = useWindowDimensions();
  const navigation = useNavigation();
    const robotRef = useRef<RobotHandle>(null);

    let timeoutId;

    const { status, data, isFetching, isLoading, refetch } = useQuery({
      queryKey: ['companyList'],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        const data = await getCompanyList().then(res => {
          return res
        });
        return data;
      },
      retry: 1,
      retryDelay: 10000,
      staleTime: 10000,
    });

    useEffect(() => {
      handleGetCompanies();
    }, [])

    const stuckNotesLength = () => {
      if (stuckNotes.length != 0) {
        return true
      }
      return false
    }

    const isCurrent = useCallback((item: string) => {
      if (!item) {
        return false;
      }
      else if (currentCompany?.id == item) {
        return true;
      } else return false;
    }, [])

    const _renderCompanyList = ({ item }) => {
      return (
        <Pressable disabled={isCurrent(item._id)} onPress={() => { dispatch({ type: SWITCH_COMPANY, payload: { id: item._id, logo: item.logo, companyName: item.companyName } }); navigation.navigate('LoadingScreen'); queryClient.removeQueries(); setSelectCompanyOpen(false); }} style={{ minHeight: 60, flex: 1, borderRadius: 10, backgroundColor: isCurrent(item._id) ? 'rgb(90,192,234)' : 'white', flexDirection: 'row', gap: 10, alignContent: 'center' }
        }>
          <Image source={{ uri: item.logo }} style={{ resizeMode: "contain", left: 5, top: 8, borderRadius: 40, flex: 1, height: 40, width: 40, maxWidth: 40 }} />
          <View style={{ padding: 10 }}>
            <Text style={{ fontWeight: '600', fontSize: 16 }}>{toTitleCase(item.company_name)}</Text>
            <Text>{userDetails.email}</Text>
          </View>
        </Pressable >
      )
    } 

    const handleGetCompanies = async () => {
      await getCompanyList().then(companies => {
        setCompanyList(companies)
      })
    }

    const handleShowHelp = useCallback(() => {
      setCurrentIndex(currentIndex + 1 > textHelp.length - 1 ? 0 : currentIndex + 1);
      setHelp(true);
    }, [currentIndex, textHelp.length])



    const getText = () => {
      return textHelp[currentIndex];
    }

    const pinnedChecker = () => {
      if (stickyNotes.noteDragged && stickyNotes.pinnedNotes.length <= 3) {
        setTimeout(() => { dispatch({ type: PIN_NOTE, payload: { id: stickyNotes.activeNote.id, content: stickyNotes.activeNote.content } }); dispatch({ type: NOTE_DROPPED }) }, 300)
      } else if (stickyNotes.noteDragged) {
        alert('Cannot pin more than 4 notes!');
      }
    }

    const handlePanGesture = ({ nativeEvent }) => {
      const { translationX, translationY, state, absoluteX, absoluteY } = nativeEvent;
      if (state === State.ACTIVE) {
        translateX.value = translationX;
      }
      if (state === State.END) {
        if (translateX.value < -150) {
          translateX.value = withSpring(0);
          setCurrentIndex(currentIndex + 1);
          setHelp(false);
          // Handle your logic when translationX is less than -1
        } else if (translateX.value < -1) {
          translateX.value = withSpring(0);
        }
        else if (translateX.value > 1) { translateX.value = withSpring(0); }
      }
    };


    const handleHoverIn = () => {
      if (!stuckNotes.length) return;
      setHidden(true);
      pinnedChecker();
      // Clear the timeout if it's set
      clearTimeout(timeoutId);
    };

    const handleHoverOut = () => {
      // Set a new timeout
      timeoutId = setTimeout(() => setHidden(false), 2500);
    };

    return (
        <View style={{ shadowRadius: 20, shadowOpacity: .2, flexDirection: 'row', alignSelf: "stretch", flexGrow: 1, justifyContent: 'center', paddingHorizontal: "5%", alignContent: 'center', alignItems: 'center', backgroundColor: theme ? "white" : "#333333", zIndex: 100, position: 'absolute', top: 0, left: 0, right: 0, height: 70 }}>
          <View style={{ flexGrow: 1, minWidth: 25, maxWidth: 150, backgroundColor: ' transparent' }}>
            {
              selectCompanyOpen ?
                <Pressable onPress={() => setSelectCompanyOpen(false)} style={{ width: width, zIndex: -1, left: -20, alignContent: 'flex-end', alignItems: 'flex-end' }}>
                  <Animatable.View animation={'slideInLeft'} duration={100} style={[styles.noteContainer, { flex: 1, padding: 20, paddingLeft: 30, paddingBottom: 150, position: 'absolute', minHeight: "100%", width: "35%", shadowOffset: { height: 15, width: 0 }, maxWidth: 400, left: 0, top: 50, shadowOpacity: .2, flexDirection: 'column', flexWrap: 'nowrap', borderRadius: 20, gap: 10, shadowRadius: 20, zIndex: -10, backgroundColor: theme ? 'white' : '#333333' }]}>
                    <View style={{ flexDirection: 'row', alignSelf: "stretch" }}>
                      <Title style={{ flex: 5, textAlign: 'left', justifyContent: "flex-start", alignSelf: 'stretch', color: theme ? 'black' : 'white' }}>Your Accounts</Title>
                      <Pressable style={{ flex: 1, alignSelf: 'flex-end', marginVertical: 4 }} disabled={help} onPress={() => handleShowHelp()}>
                        <FontAwesome name="question-circle" size={24} color={theme ? "black" : "white"} style={{ flex: 1, marginHorizontal: 10, maxWidth: 20 }} />
                      </Pressable>
                    </View>
                    <FlatList
                      data={companyList}
                      style={{ alignSelf: 'stretch', minWidth: '80%', borderRadius: 20, backgroundColor: theme ? '#ececec' : "black" }}
                      contentContainerStyle={{ padding: 10, gap: 10 }}
                      ListHeaderComponent={(
                        <Pressable disabled={!currentCompany}
                          onPress={() => { dispatch({ type: CLEAR_COMPANY }); navigation.navigate('LoadingScreen'); queryClient.removeQueries(); setSelectCompanyOpen(false); }}
                          style={{ flex: 1, borderRadius: 10, backgroundColor: !currentCompany ? 'rgb(90,192,234)' : 'white', flexDirection: 'row', gap: 10, alignContent: 'center' }}>
                          <Image source={{ uri: userDetails.avatar.img }} style={{ left: 5, top: 8, borderRadius: 40, flex: 1, height: 40, maxWidth: 40 }} />
                          <View style={{ padding: 10 }}>
                            <Text style={{ fontWeight: '600', fontSize: 16 }}>{toTitleCase(userDetails.name)} {toTitleCase(userDetails.surname)}</Text>
                            <Text>{userDetails.email}</Text>
                          </View>
                        </Pressable>)
                      }
                      renderItem={_renderCompanyList}
                      keyExtractor={item => item._id}
                    />
                    <View style={{ flexDirection: 'row', maxHeight: 40, flex: 1, borderRadius: 15, backgroundColor: '#ececec', padding: 10, alignSelf: 'stretch' }}>
                      <FontAwesome name="plus" size={24} color="black" style={{ flex: 1, marginHorizontal: 10, maxWidth: 20 }} />
                      <Pressable hitSlop={5} style={{ flex: 5 }} onPress={() => { dispatch({ type: SHOW_ADD_COMPANY_MODAL }); setSelectCompanyOpen(false) }}>
                        <Text adjustsFontSizeToFit style={{ fontSize: 18, color: 'black', textAlign: 'center' }}>Add Company?</Text>
                      </Pressable>
                    </View>
                  </Animatable.View>
                  <View>
                    <View style={{ bottom: 300, position: "absolute", left: 300 }}>
                      <SwipeableRobot ref={robotRef} textHelp={textHelp} />
                    </View>
                  </View>
                </Pressable>
                :
                null
            }
            {
              authenticated && userID ?
                <View style={{ flexDirection: 'row', minWidth: 150, minHeight: "100%", justifyContent: 'center', alignContent: 'center', zIndex: 10 }}>
                  <Pressable onPress={() => { navigation.navigate('Settings', { screen: 'Upgrade' }) }} style={{ justifyContent: "center", alignSelf: "center", minHeight: "100%", maxWidth: 90, flex: 1, backgroundColor: 'gold', elevation: 1, padding: 10, zIndex: -1 }}>
                    <Text adjustsFontSizeToFit selectable={false} style={{ fontWeight: '600', color: 'white', textAlign: 'center' }}>Pro</Text>
                  </Pressable>
                  {
                    currentCompany ?
                      <Pressable onPress={() => setSelectCompanyOpen((prev) => !prev)} style={{ flex: 1, top: 3, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, elevation: 1, shadowRadius: 10, shadowOpacity: currentCompany?.logo ? 0 : .3, padding: 10 }}>
                        {
                          currentCompany.logo ?
                            <Image source={{ uri: currentCompany.logo }} style={[styles.customerLogo, { justifyContent: "center", alignSelf: "center", backgroundColor: theme ? "white" : '#38373A', maxHeight: '100%', left: -20, top: -3, minHeight: 70, overflow: 'hidden', resizeMode: "contain" }]} />
                            :
                            <Text numberOfLines={1} style={{ fontSize: 11, color: theme ? 'black' : 'white' }}>{toTitleCase(currentCompany.companyName)}</Text>
                        }
                      </Pressable>
                      :
                      <Pressable onPress={() => setSelectCompanyOpen((prev) => !prev)} style={[styles.centerEverything, { minWidth: 100, flex: 1, backgroundColor: theme ? 'white' : 'black', elevation: 1 }]}>
                        <Text style={{ color: theme ? 'black' : 'white', }} numberOfLines={1} selectable={false} adjustsFontSizeToFit>Hobbyist</Text>
                      </Pressable>
                  }
                </View>
                :
                <View style={{ flex: 1, marginTop: 3, padding: 10 }}>
                  <Image style={{ flex: 1 }} contentFit='contain' source={require('@assets/images/1x/app-icon-2-01.png')} />
                  {/* <Logo width={'25%'} preserveAspectRatio={'xMinYMax meet'} viewBox={'0 0 1400 1400'} /> */}
                </View>
            }
          </View>
          {
            authenticated &&
            <Animatable.View animation={'slideInDown'} style={{ flex: 10, flexDirection: "row" }}>
              <WebPressable onHoverIn={handleHoverIn}
                onHoverOut={handleHoverOut} style={{ flexDirection: 'row', flexGrow: 8, maxWidth: "70%" }} hitSlop={0} >
                <Animatable.View transition={'marginTop'} style={{ marginTop: hidden ? 200 : -200, flexDirection: 'row', paddingHorizontal: 30, flex: 1 }}>
                  {
                      stuckNotesLength() &&
                      <WebPressable onPress={() => { dispatch({ type: CLEAR_ALL_NOTES }) }} style={{ marginTop: 10 }}>
                          <MaterialCommunityIcons name={"delete"} size={30} color={'rgba(0,0,0,0.25)'} />
                        </WebPressable>
                    }
                    <ScrollView horizontal>
                      {
                        stuckNotes.map((note, i) => {
                          return (
                            <RenderNote note={note} isStuck />
                          )
                        })
                      }
                    </ScrollView>
                  </Animatable.View>
                </WebPressable>
                <StatusBar />
              </Animatable.View>
          }
      </View >
    )
  }

export default HeaderBar;