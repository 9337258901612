import * as React from "react";
import { ImageStyle, Platform, Pressable, StyleProp, Text, View, Image } from 'react-native';
import { ElementsText, window } from "./constants";
import { FontAwesome } from '@expo/vector-icons';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const PAGE_WIDTH = window.width;

type HorizontalCarouselType = {
  images: Array<string>;
  width?: number;
  height?: number
  showArrows?: boolean;
  imageStyle?: StyleProp<ImageStyle>,
  removeSampleMark?: boolean;
  arrowsOnBottom?: boolean;
  withShadow?: boolean;
  autoPlay?: boolean
}

const HorizontalCarousel = ({ autoPlay = true, withShadow, arrowsOnBottom, images, width, height, showArrows = false, imageStyle, removeSampleMark }: HorizontalCarouselType) => {
  const [data, setData] = React.useState([...new Array(4).keys()]);
  const ref = React.useRef(null);
  const [heightState, setHeightState] = React.useState(0);
  const [widthState, setWidthState] = React.useState(width || 0);

  React.useEffect(() => {
    if (images?.length) {
      setData(images);
    }
  }, []);


  return (
    <View style={{ flex: 1, flexDirection: !arrowsOnBottom ? 'row' : "column", alignSelf: "stretch", flexGrow: 10, borderRadius: 20 }}
      onLayout={(e) => { setHeightState(e.nativeEvent.layout.height - 2); if (!width) setWidthState(e.nativeEvent.layout.width) }}>
      {
        showArrows && !arrowsOnBottom &&
        <Pressable onPress={() => ref.current?.prev()} style={{ zIndex: 1, left: -5, maxWidth: 50, justifyContent: 'center', top: 15 }}>
          <FontAwesome name="chevron-left" size={50} color={"rgb(90,192,234)"} />
        </Pressable>
      }
      {
        !images?.length ?
        <View style={{ borderRadius: 20, padding: 10, pointerEvents: 'none', position: 'absolute', alignSelf: 'center', justifyContent: 'center', zIndex: 10, margin: 20 }}>
          <Text style={{ color: 'white', fontSize: 24, fontWeight: 'bold' }}>Sample Images</Text>
            <Text style={{ color: 'white', maxWidth: 150 }}>This item does not have any images</Text>
        </View>
          :
          <View style={{ flex: 1, alignSelf: "stretch", minHeight: height || "100%", shadowRadius: 20, borderRadius: 20, shadowOpacity: withShadow ? 0.2 : 0, padding: 2, alignItems: "center", maxWidth: widthState || width }}>
        <Carousel
              // centerMode
          autoPlay
          swipeable={images.length > 1}
          infiniteLoop={autoPlay}
          showThumbs={false}
          showArrows={showArrows}
          dynamicHeight
          useKeyboardArrows={Platform.OS === 'web'}
          showStatus={false}
        >
          {
            data.map((item, index) => {
              return (
                <Image key={index + item.toString()} source={{ uri: item }} style={[{ minHeight: 200, borderRadius: 20, resizeMode: "cover", flex: 1 }, imageStyle, { backgroundColor: "white", minWidth: widthState / 2.4, width: imageStyle?.width, minHeight: heightState }]} />
              )
            })
          }
        </Carousel>
      </View>
      }
      {/* {
        showArrows && !arrowsOnBottom &&
        <Pressable onPress={() => ref.current?.next()} style={{ maxWidth: 50, justifyContent: 'center', top: 15, left: -50 }}>
          <FontAwesome name="chevron-right" size={50} color={"rgb(90,192,234)"} />
        </Pressable>
      } */}
    </View>
  );
}

export default HorizontalCarousel;