import { Platform } from 'react-native';
import {
	SHOW_COMMUNITY_MODAL,
	SHOW_AUTH_MODAL,
	SHOW_LANGUAGE_MODAL,
	SHOW_HELP_MODAL,
	SHOW_LOADING_MODAL,
	SHOW_MESSAGE_MODAL,
	HIDE_COMMUNITY_MODAL,
	HIDE_AUTH_MODAL,
	HIDE_LANGUAGE_MODAL,
	HIDE_HELP_MODAL,
	HIDE_LOADING_MODAL,
	HIDE_MESSAGE_MODAL,
	TOGGLE_MESSAGE_MODAL,
	SHOW_LOCK_MODAL,
	HIDE_LOCK_MODAL,
	FLIP_MESSAGE_MODAL,
	SHOW_USER_CONTEXT_MODAL,
	HIDE_USER_CONTEXT_MODAL,
	GET_MESSAGE_CONTENT,
	SHOW_REGISTER_MODAL,
	HIDE_REGISTER_MODAL,
	SHOW_PASSWORD_RESET_MODAL,
	HIDE_PASSWORD_RESET_MODAL,
	SET_SELECTED_PLAN,
	SHOW_NOTIFICATIONS_MODAL,
	HIDE_NOTIFICATIONS_MODAL,
	SHOW_ACHIEVEMENT_MODAL,
	HIDE_ACHIEVEMENT_MODAL,
	GET_NOTIFICATIONS_CONTENT,
	SHOW_ADD_COMPANY_MODAL,
	HIDE_ADD_COMPANY_MODAL,
	SHOW_PROJECT_MODAL,
	HIDE_PROJECT_MODAL,
	SHOW_CALENDAR_ENTRY_MODAL,
	HIDE_CALENDAR_ENTRY_MODAL,
	TOGGLE_NOTIFICATIONS_MODAL,
	SHOW_LIGHTNING_MODAL,
	HIDE_LIGHTNING_MODAL,
	TOGGLE_LIGHTNING_MODAL,
	UPDATE_PRODUCTS,
	SHOW_TOOLBAR,
	HIDE_TOOLBAR,
	CLEAR_SELECTED_PLAN,
	INITIALISE_MODALS,
	SHOW_STICKY_NOTES_MODAL,
	HIDE_STICKY_NOTES_MODAL,
	UPDATE_AUTH_MODAL,
	UPDATE_CONVERSATION_ID,
	SHOW_TC_MODAL,
	HIDE_TC_MODAL,
	ACCEPT_TERMS,
	DECLINE_TERMS,
	HIDE_ALL_MODALS,
	SHOW_FIRST_TUTORIAL,
	HIDE_FIRST_TUTORIAL,
	SHOW_DATA_DELETE_CONFIRM,
	SHOW_DATA_OPTIONS,
	SHOW_DATA_SHARE_USER,
	SHOW_DATA_ACTIONS,
	HIDE_DATA_DELETE_CONFIRM,
	HIDE_DATA_OPTIONS,
	HIDE_DATA_SHARE_USER,
	HIDE_DATA_ACTIONS,
	SHOW_MAIL_MODAL,
	HIDE_MAIL_MODAL,
	SHOW_FEEDBACK_MODAL,
	FEEDBACK_MODAL_HIDDEN,
	SHOW_NOTIFICATIONS_PANEL,
	HIDE_NOTIFICATIONS_PANEL,
	TOGGLE_NOTIFICATIONS_PANEL,
	SHOW_UPDATE_MODAL,
	HIDE_UPDATE_MODAL,
	TOGGLE_UPDATE_MODAL,
} from '../actions/types';
import { updateObject } from './utils/globalStateFunctions';
import * as Haptics from 'expo-haptics';

const initialState = {
	communityModal: {
		modalDisplayed: false,
		content: 'null',
	},
	authModal: {
		modalDisplayed: false,
		modalContent: '',
		modalType: '',
	},
	helpModal: {
		modalDisplayed: false,
		content: 'null',
	},
	mailModal: false,
	languageModal: {
		modalDisplayed: false,
		content: 'null',
	},
	loadingModal: false,
	messageModal: {
		modalDisplayed: false,
		flipped: false,
		content: {},
		userSettingsModal: false,
		messageContent: {},
	},
	lockModal: {
		modalDisplayed: false,
	},
	registerModal: {
		modalDisplayed: false,
		selectedPlan: null,
		products: {},
		heldValues: {},
	},
	notificationsModal: {
		modalDisplayed: false,
		modalData: null,
		full: false,
	},
	notificationsPanel:{
		modalDisplayed: false
	},
	achievementModal: {
		modalDisplayed: false,
		modalContent: null,
	},
	formModal: {
		modalDisplayed: false,
		modalType: '',
	},
	tcModal: {
		modalDisplayed: false,
		accepted: false,
		userId: null,
	},
	feedbackModal: {
		modalDisplayed: false,
	},
	updateModal: {
		modalDisplayed: false,
	},
	firstTutorial: { modalDisplayed: false },
	sitckyNotesModalDisplayed: false,
	passwordModalDisplayed: false,
	addProjectModalDisplayed: false,
	calendarEntryModalDisplayed: false,
	lightningModalDisplayed: false,
	toolbar: { toolbarDisplayed: false, toolbarContext: [{}] },
	dataModals: {
		activeItem: {},
		deleteConfirm: false,
		dataOptions: false,
		shareUser: false,
		dataActions: false,
	},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SHOW_UPDATE_MODAL: 
			return updateObject(state, {
				updateModal: {
					modalDisplayed: true,
				},
			});
			case HIDE_UPDATE_MODAL:
			return updateObject(state, {
				updateModal: {
					modalDisplayed: false,
				},
			});
			case TOGGLE_UPDATE_MODAL:
			return updateObject(state, {
				updateModal: {
					modalDisplayed: !state.updateModal.modalDisplayed,
				},
			});
		case SHOW_FEEDBACK_MODAL:
			return updateObject(state, {
				...initialState,
				feedbackModal: {
					modalDisplayed: true,
				},
			});
			case FEEDBACK_MODAL_HIDDEN:
			return updateObject(state, {
				...initialState,
				feedbackModal: {
					modalDisplayed: false,
				},
			});
		case SHOW_MAIL_MODAL:
			return updateObject(state, {
				mailModal: true,
			});
		case HIDE_MAIL_MODAL:
			return updateObject(state, {
				mailModal: false,
			});
		case SHOW_DATA_DELETE_CONFIRM:
			return updateObject(state, {
				dataModals: { ...state.dataModals, deleteConfirm: true },
			});
		case SHOW_DATA_OPTIONS:
			return updateObject(state, {
				dataModals: { ...state.dataModals, dataOptions: true },
			});
		case SHOW_DATA_SHARE_USER:
			return updateObject(state, {
				dataModals: { ...state.dataModals, shareUser: true },
			});
		case SHOW_DATA_ACTIONS:
			return updateObject(state, {
				dataModals: { ...state.dataModals, dataActions: true },
			});
		case HIDE_DATA_DELETE_CONFIRM:
			return updateObject(state, {
				dataModals: { ...state.dataModals, deleteConfirm: false },
			});
		case HIDE_DATA_OPTIONS:
			return updateObject(state, {
				dataModals: { ...state.dataModals, dataOptions: false },
			});
		case HIDE_DATA_SHARE_USER:
			return updateObject(state, {
				dataModals: { ...state.dataModals, shareUser: false },
			});
		case HIDE_DATA_ACTIONS:
			return updateObject(state, {
				dataModals: { ...state.dataModals, dataActions: false },
			});
		case SHOW_FIRST_TUTORIAL:
			return updateObject(state, {
				...state,
				firstTutorial: {
					modalDisplayed: true,
				},
			});
		case HIDE_FIRST_TUTORIAL:
			return updateObject(state, {
				...state,
				firstTutorial: {
					modalDisplayed: false,
				},
			});
		case HIDE_ALL_MODALS:
			return updateObject(state, initialState);
		case INITIALISE_MODALS:
			return updateObject(state, {
				...state,
				registerModal: {
					...state.registerModal,
					modalDisplayed: false,
					selectedPlan: null,
				},
			});
		case SHOW_COMMUNITY_MODAL:
			return updateObject(state, {
				...state,
				communityModal: {
					modalDisplayed: true,
				},
			});
		case SHOW_STICKY_NOTES_MODAL:
			return updateObject(state, {
				...state,
				stickyNotesModalDisplayed: true,
			});
		case HIDE_STICKY_NOTES_MODAL:
			return updateObject(state, {
				...state,
				stickyNotesModalDisplayed: false,
			});
		case SHOW_TOOLBAR:
			return updateObject(state, {
				...state,
				toolbar: {
					...state.toolbar,
					toolbarDisplayed: true,
					toolbarContext: action.payload,
				},
			});
		case HIDE_TOOLBAR:
			return updateObject(state, {
				...state,
				toolbar: {
					...state.toolbar,
					toolbarDisplayed: false,
					toolbarContext: [],
				},
			});
		case SHOW_CALENDAR_ENTRY_MODAL:
			return updateObject(state, {
				...state,
				calendarEntryModalDisplayed: true,
			});
		case HIDE_CALENDAR_ENTRY_MODAL:
			return updateObject(state, {
				...state,
				calendarEntryModalDisplayed: false,
			});
		case SHOW_TC_MODAL:
			return updateObject(initialState, {
				tcModal: {
					...state.tcModal,
					modalDisplayed: true,
					userId: action.payload,
				},
			});
		case HIDE_TC_MODAL:
			return updateObject(state, {
				...state,
				tcModal: {
					...state.tcModal,
					modalDisplayed: false,
					accepted: false,
					userId: null,
				},
			});
		case ACCEPT_TERMS:
			return updateObject(state, {
				...state,
				tcModal: { ...state.tcModal, accepted: true },
			});
		case DECLINE_TERMS:
			return updateObject(state, {
				...state,
				tcModal: { ...state.tcModal, accepted: false },
			});
		case SHOW_PROJECT_MODAL:
			return updateObject(state, {
				...state,
				addProjectModalDisplayed: true,
			});
		case HIDE_PROJECT_MODAL:
			return updateObject(state, {
				...state,
				addProjectModalDisplayed: false,
			});
		case SHOW_PASSWORD_RESET_MODAL:
			return updateObject(state, {
				...state,
				passwordModalDisplayed: true,
			});
		case HIDE_PASSWORD_RESET_MODAL:
			return updateObject(state, {
				...state,
				passwordModalDisplayed: false,
			});
		case UPDATE_PRODUCTS: {
			return updateObject(state, {
				...state,
				registerModal: {
					...state.registerModal,
					products: action.payload,
				},
			});
		}
		case SHOW_ADD_COMPANY_MODAL:
			return updateObject(state, {
				...state,
				formModal: {
					modalDisplayed: true,
					modalType: 'Add Company',
				},
			});
		case HIDE_ADD_COMPANY_MODAL:
			return updateObject(state, {
				...state,
				formModal: {
					modalDisplated: false,
					modalType: '',
				},
			});
		case GET_NOTIFICATIONS_CONTENT:
			return updateObject(state, {
				...state,
				notificationsModal: {
					...state.notificationsModal,
					modalData: action.payload,
				},
			});
		case SHOW_AUTH_MODAL:
			if (Platform.OS != 'web') {
				if (state.authModal.modalType === 'error') {
					Haptics.notificationAsync(
						Haptics.NotificationFeedbackType.Error
					);
				} else if (state.authModal.modalType === 'warning') {
					Haptics.notificationAsync(
						Haptics.NotificationFeedbackType.Warning
					);
				} else {
					Haptics.notificationAsync(
						Haptics.NotificationFeedbackType.Success
					);
				}
			}
			return updateObject(initialState, {
				authModal: {
					...state.authModal,
					modalDisplayed: true,
					modalContent: action.data?.content,
					modalType: action.data?.type,
					details: action.data?.details
						? action.data?.details
						: undefined,
				},
			});
		case UPDATE_AUTH_MODAL:
			return updateObject(state, {
				...state,
				authModal: {
					...state.authModal,
					modalContent: action.data.content,
					modalType: action.data.type,
					details: action.data.details
						? action.data.details
						: undefined,
				},
			});
		case SHOW_LANGUAGE_MODAL:
			return updateObject(state, {
				...state,
				languageModal: {
					modalDisplayed: true,
				},
			});
		case SHOW_ACHIEVEMENT_MODAL:
			return updateObject(state, {
				...state,
				achievementModal: {
					modalDisplayed: true,
					modalContent: action.payload.data,
				},
			});
		case SHOW_HELP_MODAL:
			return updateObject(state, {
				...state,
				helpModal: {
					modalDisplayed: true,
				},
			});
		case SHOW_LOADING_MODAL:
			return updateObject(state, {
				...state,
				loadingModal: true,
			});
		case SHOW_MESSAGE_MODAL:
			return updateObject(state, {
				...state,
				messageModal: {
					modalDisplayed: true,
				},
			});
		case SHOW_NOTIFICATIONS_MODAL:
			return updateObject(state, {
				...state,
				notificationsModal: {
					modalDisplayed: true,
					full: action.payload ? true : false,
				},
			});
			case SHOW_NOTIFICATIONS_PANEL:
				return updateObject(state, {
					...state,
					notificationsPanel: {
						modalDisplayed: true,
					},
				});
				case HIDE_NOTIFICATIONS_PANEL:
					return updateObject(state, {
						...state,
						notificationsPanel: {
							modalDisplayed: false,
						},
					});
					case TOGGLE_NOTIFICATIONS_PANEL:
						return updateObject(state, {
							...state,
							notificationsPanel: {
								modalDisplayed: !state.notificationsPanel.modalDisplayed,
							},
						});
		case GET_MESSAGE_CONTENT:
			return updateObject(state, {
				...state,
				messageModal: {
					...state.messageModal,
					messageContent: {
						...state.messageModal.messageContent,
						conversationId: action.data._id,
					},
				},
			});
		case UPDATE_CONVERSATION_ID:
			return updateObject(state, {
				...state,
				messageModal: {
					...state.messageModal,
					messageContent: {
						...state.messageModal.messageContent,
						conversationId: action.payload,
					},
				},
			});
		case SHOW_LOCK_MODAL:
			return updateObject(state, {
				...state,
				lockModal: {
					modalDisplayed: true,
				},
			});
		case SHOW_REGISTER_MODAL:
			return updateObject(state, {
				...state,
				registerModal: {
					...state.registerModal,
					modalDisplayed: true,
					heldValues: action.payload,
				},
			});
		case SET_SELECTED_PLAN: {
			return updateObject(state, {
				...state,
				registerModal: {
					...state.registerModal,
					selectedPlan: {
						...state.registerModal.selectedPlan,
						id: action.data.id,
						name: action.data.name,
						plan: action.data.plan,
					},
				},
			});
		}
		case CLEAR_SELECTED_PLAN: {
			return updateObject(state, {
				...state,
				registerModal: {
					...state.registerModal,
					modalDisplayed: false,
					selectedPlan: null,
				},
			});
		}
		case TOGGLE_MESSAGE_MODAL:
			const currentState = state.messageModal.modalDisplayed;
			return updateObject(state, {
				...state,
				messageModal: {
					...state.messageModal,
					flipped: false,
					modalDisplayed: !currentState,
				},
			});
		case SHOW_USER_CONTEXT_MODAL:
			return updateObject(state, {
				...state,
				messageModal: {
					...state.messageModal,
					userSettingsModal: true,
					userSettingsContent: action.payload,
				},
			});
		case HIDE_USER_CONTEXT_MODAL:
			return updateObject(state, {
				...state,
				messageModal: {
					...state.messageModal,
					userSettingsModal: false,
					userSettingsContent: {},
				},
			});
		case FLIP_MESSAGE_MODAL:
			const messageFlip = state.messageModal.flipped;
			return updateObject(state, {
				...state,
				messageModal: {
					...state.messageModal,
					flipped: !messageFlip,
					content: action.payload,
					messageContent: state.messageModal.messageContent,
				},
			});
		case HIDE_ACHIEVEMENT_MODAL:
			return updateObject(state, {
				...state,
				achievementModal: {
					modalDisplayed: false,
					modalContent: null,
				},
			});
		case HIDE_LOCK_MODAL:
			return updateObject(state, {
				...state,
				lockModal: {
					modalDisplayed: false,
				},
			});
		case HIDE_COMMUNITY_MODAL:
			return updateObject(state, {
				...state,
				communityModal: {
					modalDisplayed: false,
					content: '',
				},
			});
		case HIDE_NOTIFICATIONS_MODAL: {
			return updateObject(state, {
				...state,
				notificationsModal: {
					modalDisplayed: false,
				},
			});
		}
		case HIDE_AUTH_MODAL:
			return updateObject(state, {
				...state,
				authModal: {
					modalDisplayed: false,
					modalContent: '',
					modalType: '',
				},
			});
		case HIDE_LANGUAGE_MODAL:
			return updateObject(state, {
				...state,
				languageModal: {
					modalDisplayed: false,
					content: '',
				},
			});
		case HIDE_HELP_MODAL:
			return updateObject(state, {
				...state,
				helpModal: {
					modalDisplayed: false,
				},
			});
		case HIDE_LOADING_MODAL:
			return updateObject(state, {
				...state,
				loadingModal: false,
			});
		case HIDE_MESSAGE_MODAL:
			return updateObject(state, {
				...state,
				messageModal: {
					...state.messageModal,
					modalDisplayed: false,
					messageContent: null,
				},
			});
		case HIDE_REGISTER_MODAL:
			return updateObject(state, {
				...state,
				registerModal: {
					...state.registerModal,
					modalDisplayed: false,
					heldValues: {},
					products: {},
				},
			});
		case SHOW_LIGHTNING_MODAL:
			return updateObject(state, {
				...state,
				lightningModalDisplayed: true,
			});
		case HIDE_LIGHTNING_MODAL:
			return updateObject(state, {
				...state,
				lightningModalDisplayed: false,
			});
		case TOGGLE_LIGHTNING_MODAL:
			let lightningModalState = state.lightningModalDisplayed;
			return updateObject(state, {
				...state,
				lightningModalDisplayed: !lightningModalState,
			});
		case TOGGLE_NOTIFICATIONS_MODAL:
			let notificationState = state.notificationsModal.modalDisplayed;
			return updateObject(state, {
				...state,
				notificationsModal: {
					modalDisplayed: !notificationState,
				},
			});
		default:
			return state;
	}
}
