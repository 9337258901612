import { View, FlatList, Text, Pressable, Platform } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { DefaultRootState } from '@reduxLocal/persistState';
import { useDispatch, useSelector } from 'react-redux';
import ProductItem from './ProductItem';
import Animated from 'react-native-reanimated';
import AnimatedLottieView from 'lottie-react-native';
interface ProductListProps {
  refresh?: boolean;
  setRefresh?: (val: boolean) => void;
  type?: string;
}


const ProductList = ({ refresh, setRefresh, type }: ProductListProps) => {
  const productList = useSelector((state: DefaultRootState) => state.store.productList);
  const [retrievedProducts, setRetrievedProducts] = useState(productList);
  const [containerWidth, setContainerWidth] = useState(0);
const dispatch = useDispatch();
  const getNearestWholeNumber = () => {
    const returnValue = Math.floor(containerWidth / 350);
    return returnValue;
  }

  const _handleSwipe = () => { console.log('swiped') }


  useEffect(() => {
    setRetrievedProducts([]);
  }, [type])

  useEffect(() => {
    setColumns(getNearestWholeNumber());
  }, [containerWidth])

  const [columns, setColumns] = useState(getNearestWholeNumber());

  return (
    <View onLayout={e => setContainerWidth(e.nativeEvent.layout.width)} style={{ flex: 1 }}>
      {
        !productList ?
          <AnimatedLottieView source={require('@assets/animations/leaf_Loader.json')} autoPlay loop style={{ width: 100, height: 100, alignSelf: 'center', flex: 1 }} />
          :
          <FlatList 
          // onScroll={() => _handleSwipe()} 
            key={columns}
            showsVerticalScrollIndicator={false}
          numColumns={columns} 
            contentContainerStyle={{ gap: 30 }}
            data={productList}
          renderItem={({ item, index }) => (<ProductItem item={item} index={index} type={type} />)} 
            style={{ paddingHorizontal: 20, padding: 20, }}
             />
      }
    </View>
  )
}
export default ProductList;