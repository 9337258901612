import { Entypo, FontAwesome, FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Image, Modal as SmallModal, Pressable, ScrollView, Text, View, TextInput, Animated, UIManager, LayoutAnimation, Dimensions, Platform } from 'react-native';
import { FlashList } from '@shopify/flash-list'
import * as Animatable from 'react-native-animatable';
import { Title } from "@constants/Themed";
import { useDispatch, useSelector } from 'react-redux';
import { FLIP_MESSAGE_MODAL, GET_MESSAGE_CONTENT, SHOW_HELP_MODAL, SHOW_MESSAGE_MODAL, TOGGLE_MESSAGE_MODAL, UPDATE_CONVERSATION_ID } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import styles from "@stylesheet";
import apis from '@api/api';
import MessageUserList from './Components/MessageUserList';
import filter from 'lodash.filter';
import FlipCard from 'react-native-flip-card';
import MessageSettings from "./Components/MessageSettings";
import SwitchWithIcons from 'react-native-switch-with-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { MobileChatIcon, SmartSaaSIcon } from "@assets/--native";
import ToolBar from "../ToolBar/ToolBar";
import { Bubble, MessageProps, BubbleProps, GiftedChat, MessageImage, Send, SystemMessage, IMessage, InputToolbarProps, MessageContainer, MessageContainerProps, GiftedChatProps } from 'react-native-gifted-chat';
import useGlobalUser from "@hooks/getGlobalUser";
import SocketManager from '@utils/socket';
import { connect } from 'react-redux';
import toTitleCase from '@utils/toTitleCase';
import { Audio } from 'expo-av';
import InputToolbar from "./Components/InputToolbar";
import { themeSelector } from "@reduxLocal/selectors/themeSelector";
import LoadingIndicator from "@components/Global/Spinner";
import { heightPercentageToDP, widthPercentageToDP } from "./Components/Dimensions";
import LottieView from "lottie-react-native";
import ChatTab, { ChatTabProps } from "./Components/ChatTab";
import { FontAwesome6 } from "@expo/vector-icons";
import ContextHandler from "@navigation/ContextHandler/ContextHandler";
import GetAvatarImage from "@hooks/getAvatarImage";
import availabilityColor from "@utils/availabilityColor";
import { useQuery } from "react-query";
import { Badge } from "@mui/material";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { selectUser } from "@reduxLocal/selectors/userSelectors";
import ErrorBoundary from "@utils/ErrorBoundary";

const socketManager = new SocketManager('messaging');

const Messages = ({ messages, messageFlip, convoId, statusBarHeight }) => {
  const visibleState = useSelector((state: DefaultRootState) => state.modals.messageModal.modalDisplayed);
  const activeLanguage = useSelector((state: DefaultRootState) => state.app.language.value)
// const [visibleState, setVisibleState] = useState(false);
  const theme = themeSelector();
  const route = useRoute();
  const navigation = useNavigation();
  const contextAvailable = useSelector((state: DefaultRootState) => state.UI.contextAvailable);
  const contextMinimized = useSelector((state: DefaultRootState) => state.UI.contextMinimized);
  const selectedConversation = useSelector((state: DefaultRootState) => state.modals.messageModal.content);
  const conversationId = selectedConversation?.conversationId
  const [conversationIdState, setConversationIdState] = useState("");
  const { userData, companyData } = useSelector((state: DefaultRootState) => state.promiseData.data);
  const { toolbarDisplayed } = useSelector((state: DefaultRootState) => state.modals.toolbar);
  const [listWidth, setListWidth] = useState(0);
  const user = userData;
  const company = companyData;
  const companyID = company?.companyId;
  const [searchVisible, setSearchVisible] = useState(true);
  const [sound, setSound] = React.useState<Audio.Sound>();
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  const containerRef = useRef(null);
  const [fullData, setFullData] = useState([]);
  const [isUserOrganisation, setIsUserOrganisation] = useState(true);
  const toggleSwitch = () => setIsUserOrganisation(previousState => !previousState);
  const globalUser = selectUser();
  const userId = userData.id;
  const [messageArray, setMessageArray] = useState([]);
  const [badgeCount, setBadgeCount] = useState(0);
  const [isConnected, setIsConnected] = useState(false);
  const [chatFeatureState, setChatFeatureState] = useState("default");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pendingImageUpload, setPendingImageUpload] = useState(null);
  const dispatch = useDispatch();
  const [chatKey, setChatKey] = useState(0);
  const { t } = useTranslation();

  const availableState = useSelector((state: DefaultRootState) => state.app.availability);
  const socket = socketManager.getSocket(userId);

  useEffect(() => {
    if (user) {
      _getAllUsers();
    }
    if (conversationIdState === 'blank') {
      setTimeout(() => setMessageArray(GiftedChat.append([], [{
        _id: 1,
        text: 'Start a conversation with ' + selectedConversation.name + ' ' + selectedConversation.surname + ' by sending a message',

        createdAt: new Date(),
        system: true,
        user: {
          _id: 2,
          name: 'System',
        },
      }])), 1000)
    }
  }, [conversationIdState, user])

  const _getAllUsers = async () => {
    try {
      // Fetch users and conversations concurrently
      const [users, conversations] = await Promise.all([apis.getAllUsers(), apis.getUserConversations()]);

      // Prepare an object to store user information with badges
      const userList = users.map(user => ({ ...user, badge: 0 }));

      if (!conversations) {
        setData(userList);
        setFullData(userList);
        setLoading(false);
        return userList;
      } else {

        // Update userList with conversation IDs
        userList.forEach(user => {
          const conversation = conversations.find(convo => convo.participants.includes(user._id));
          if (conversation) {
            user.conversationId = conversation._id;
            user.badge = conversation.messages.filter(message => message.to === userId && !message.seen).length;
          }
        });

        //Sort userList if conversationId exists and last message from messages

        userList.sort((a, b) => {
          //If messages exist, sort by last message
          if (a.conversationId && b.conversationId) {
            const aLastMessage = conversations.find(convo => convo._id === a.conversationId).messages.slice(-1)[0];
            const bLastMessage = conversations.find(convo => convo._id === b.conversationId).messages.slice(-1)[0];
            return +new Date(bLastMessage.createdAt) - +new Date(aLastMessage.createdAt);
          }
          //If no messages exist, sort by conversationId
          if (a.conversationId && !b.conversationId) return -1;
          if (!a.conversationId && b.conversationId) return 1;
          return 0;
        });

        if (Platform.OS === 'web') {
          const gcLoadingContaineEl = document.querySelectorAll('[data-testid="GC_LOADING_CONTAINER"]')[0] as HTMLElement
          if (gcLoadingContaineEl) {
            gcLoadingContaineEl.style.display = 'none'
            setTimeout(() => {
              gcLoadingContaineEl.style.display = 'flex'
            }, 500)
          }
        }

        // Update state and return userList
        setData(userList);
        setFullData(userList);
        setLoading(false);
        return userList;
      }
    } catch (error) {
      // Handle errors
      setError(error);
    }
  };

  const { data: users, isLoading, isError } = useQuery({
    queryKey: ['users'],
    queryFn: _getAllUsers,
    enabled: !!userId,
    refetchOnMount: true,
    retryDelay: 3000,
    staleTime: 1000 * 60 * 10,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  useLayoutEffect(() => { if (messageFlip) { dispatch({ type: FLIP_MESSAGE_MODAL }) } }, [globalUser])

  const messageIdCallback = useCallback((messageId, messageList = []) => {
    console.log(messageList, 'messages list')

    setConversationIdState(messageId);
    if (messageList.length > 0) {
      console.log('found messages', messageList)
      // setMessageArray(messageList.reverse())
      setTimeout(() => {
        console.log('setting message array')
        setMessageArray(prev => GiftedChat.append(prev, messageList.reverse()));
      }, 500)      
    }
  }, []);


  async function playSound() {
    const { sound } = await Audio.Sound.createAsync(require('@assets/sound/smartsaasping.wav')
    );
    setSound(sound);
    //Only play sound if window is not focused
    if (!document.hasFocus())
    await sound.playAsync();
  }

  useEffect(() => {
    return sound ? () => {
      sound.unloadAsync();
    } : undefined
  }, [sound])

  const handleMessage = useCallback(async (messages = []) => {
    //Push conversation to top of list
    if (conversationIdState !== 'blank' && data[0]?.conversationId !== conversationIdState) {
      const conversation = data.find(user => user.conversationId === conversationIdState);
      const index = data.indexOf(conversation);
      const newData = [...data];
      newData.splice(index, 1);
      newData.unshift(conversation);
      setData(newData);
    }

    const messageToSend = { ...messages[0], pending: true,  to: conversationIdState === 'blank' ? selectedConversation.id : conversationIdState };
    const newMessage = { ...messageToSend, image: pendingImageUpload ? pendingImageUpload.uri : undefined }
    setMessageArray(previousMessages =>
      GiftedChat.append(previousMessages, [newMessage])
    );
    socket.emit("message", messageToSend, convoId)

  }, [conversationIdState, pendingImageUpload, selectedConversation, messageArray]);

  const renderMessageImage = (props) => {
    return (
      <MessageImage
        {...props}
        containerStyle={{ maxWidth: 50 }}
        imageStyle={{
          maxWidth: 50,
          maxHeight: 50,
          resizeMode: 'contain'
        }}
      />
    )
  }

  const responseHandler = useCallback((event) => {
    setBadgeCount(badgeCount + 1);
    if (event.user?._id) {
      setData(previousData =>
        previousData.map(item =>
          item._id === event.user._id && selectedConversation?._id !== event.user._id
            ? { ...item, badge: item.badge + 1 }
            : item
        )
      );
      playSound();
    }
    setMessageArray(previousMessages =>
      [event, ...previousMessages]
    );
  }, [conversationIdState, selectedConversation, messageArray]);

  const acknowledgeHandler = useCallback((event) => {
    setMessageArray(previousMessageArray => previousMessageArray.map(item => {
      if (item?._id && item?._id === event?.id) {
        return { ...event, pending: false, sent: true, received: true };
      }
      return item;
    }));
  }, [conversationIdState, selectedConversation, messageArray]);

  const deliveredHandler = useCallback(({ event, messageArray }) => {
    setMessageArray(previousMessageArray => previousMessageArray.map(item => {
      if (item?.id && item?._id === event?.id) {
        return { ...item, pending: false, sent: true, received: true, delivered: true, seen: item.seen || false };
      }
      return item;
    }));
  }, []);

  useEffect(() => {
    socket.emit("join", userId);
    return () => {
      //Clear query on unmount
    }
  }, [socket, userId]);


  useEffect(() => {
    if (socket.connected) {
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
    // Subscribe to socket events
    socket.on("response", responseHandler);
    socket.on("acknowledge", acknowledgeHandler);
    socket.on("delivered", deliveredHandler);
    socket.on("set convo", handleSetConvo);
    socket.on('appendId', (id) => {
      setConversationIdState(id);
    })

    socketManager.disconnect();
    // Cleanup function (optional)
    return () => {
      // Unsubscribe from socket events when the component unmounts

      // Note: Depending on the socket library you use, this step might not be necessary
      socket.off("response", responseHandler);
      socket.off("acknowledge", acknowledgeHandler);
      socket.off("delivered", deliveredHandler);
      socket.off("set convo", handleSetConvo);
      socket.emit("leave", userId);
    };
  }, [socket]); // Ensure that the effect runs only once (componentDidMount equivalent)

  // Handler function for the "set convo" event
  const handleSetConvo = (event) => {
    dispatch({ type: UPDATE_CONVERSATION_ID, payload: event.conversationId });
  };


  //handle user search
  const handleSearch = (text) => {
    setQuery(text);
    const formattedQuery = text.toLowerCase();
    const filteredData = filter(fullData, user => {
      return contains(user, formattedQuery);
    });
    setFullData(filteredData);
  };

  const contains = ({ name, surname, department }, query) => {
    const first = name;
    const last = surname;
    // const departmentname = department;

    if (first.includes(query) || last.includes(query)) {
      return true;
    }
    return false;
  };

  function clearBadge(user) {
    const newList = data.map(item => {
      if (item._id === user._id) {
        return { ...item, badge: 0 }
      }
      return item;
    });
    setData(newList);
  }

  function badgeSum() {
    return parseInt(data.reduce((acc, item) => acc + item.badge, 0));
  }

  const renderUserItems = ({ item }) => {
    const layoutMet = listWidth > 200;
    return (
      <MessageUserList layoutWidth={layoutMet} item={item} badge={item.badge} clearBadge={(user) => clearBadge(user)} setIdCallback={messageIdCallback} messageViewRef={undefined} index={undefined} />
    )
  }

  const RenderHeader = ({ visibleState, setQuery, toggleSwitch, handleSearch, setSearchVisible }) => {
    return (
      <View >
        {
          visibleState ?
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <View style={[styles.searchInputMessages, { flex: 2.8, borderRadius: 20, justifyContent: 'center', alignItems: 'center', flexDirection: 'row', alignContent: 'center', minWidth: 100 }]}>
                <FontAwesome5 name={"search"} size={20} color={'rgba(0,0,0,0.25)'} style={{ flex: 1, maxWidth: 30, overflow: 'visible', zIndex: -1000, }} ></FontAwesome5>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                  <TextInput
                    placeholder="Search"
                    style={{ flex: 6, height: 28, backgroundColor: 'transparent', paddingHorizontal: 2, zIndex: -100, margin: -5, }}
                    onFocus={() => setSearchVisible(false)}
                    onBlur={() => setSearchVisible(true)}
                    caretHidden={true}
                    value={query}
                    onChangeText={(value) => setQuery(value)}
                    onChange={(queryText) => handleSearch(queryText)}
                  />
                </View>
              </View>
              <SwitchWithIcons style={{ flex: 1, alignSelf: 'center', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}
                onValueChange={toggleSwitch}
                value={isUserOrganisation}
                trackColor={{ false: '#58BFEA', true: '#58BFEA' }}
                thumbColor={{ false: 'grey', true: 'grey' }}
                animationDuration={150}
                icon={{ false: 'user', true: 'users' }} />
            </View>
            :
            null
        }
      </View>
    )
  }

  const tabCallback = useCallback((title) => {
    setChatFeatureState(title);
  }, [chatFeatureState])

  return (
    <Animatable.View transition={['flex']} direction="alternate" onAnimationEnd={() => console.log('ended animation')}
      style={{
        justifyContent: "flex-end",
        flexDirection: 'row',
        flex: visibleState || (!contextMinimized && contextAvailable) ? (toolbarDisplayed ? 6 : 3) : (!visibleState ? (contextAvailable ? 1.4 : 0.8) : 1.5),
        maxWidth: (contextAvailable && !visibleState) ? (contextMinimized ? 190 : (!contextAvailable && !visibleState ? 60 : 450)) : (!contextAvailable && !visibleState ? 60 : 450),
        minWidth: contextAvailable ? 190 : 60,
        right: 0,
        zIndex: 10
      }}>
      {
        toolbarDisplayed &&
        <View style={{ zIndex: 10, shadowRadius: 20, shadowOpacity:.2 }}>
          <ToolBar />
        </View>
      }
      {
        Platform.OS === 'web' &&
        <Animatable.View animation={'slideInRight'} style={{ right: toolbarDisplayed ? 51 : undefined, pointerEvents: !contextAvailable ? "none" : undefined, backgroundColor: "transparent", flex: 1, position: 'absolute', justifyContent: "flex-start", alignSelf: "flex-start", minHeight: "100%", maxHeight: "100%", shadowRadius: 20, shadowOpacity: !contextAvailable ? 0 : .2 }}>
          <ContextHandler route={route} navigation={navigation} />
      </Animatable.View>
      }
      <Animatable.View transition={'maxWidth'} style={{ justifyContent: "flex-end", flex: 1, elevation: 1, alignContent: "flex-end", shadowRadius: 10, shadowColor: theme ? undefined : "white", minWidth: 60, maxWidth: !visibleState ? 50 : 380, shadowOpacity: .2, shadowOffset: { width: 0, height: 5 }, zIndex: 5000 }}>
        <LinearGradient
          // Background Linear Gradient
          colors={['#1E90FF', 'transparent']}
          style={{ flex: 1, flexDirection: 'row', zIndex: 3, padding: 15, elevation: 1, shadowRadius: 10, shadowOffset: { height: 0, width: -15 }, shadowOpacity: 0.2, backgroundColor: theme ? '#58BFEA' : '#3a8099', maxHeight: 50 }}>
          {
            messageFlip &&
            <Pressable onPress={() => { dispatch({ type: TOGGLE_MESSAGE_MODAL, payload: null }) }} style={{ flex: .15, backgroundColor: "rgb(90,192,234)", borderRadius: 35, shadowRadius: 20, shadowOpacity: .2, left: -30, top: -10, minHeight: 35, minWidth: 35 }}>
              <MaterialCommunityIcons name={"backburger"} size={30} style={{ margin: 3 }} color={"white"}></MaterialCommunityIcons>
              </Pressable>
          }
          {
            visibleState &&
              <View style={{ flexDirection: 'row', alignSelf: 'stretch', flex: 1 }}>
                <Title selectable={false} style={{ flex: 4, margin: -5, color: theme ? 'white' : 'white', fontWeight: '400' }}>{t('messageHeader')}</Title>
                <View style={{ flex: 1, flexShrink: 0.1 }} />
                <View style={{ flex: 2, flexDirection: 'row' }}>
                  <Pressable style={{ marginHorizontal: 10 }} onPress={() => { }}>
                    <MaterialCommunityIcons name={"message-settings-outline"} size={20} color={'rgba(255, 255, 255,0.8)'}></MaterialCommunityIcons>
                  </Pressable>
                  <Pressable style={{ marginHorizontal: 10 }} onPress={() => { }}>
                    <Entypo name={"popup"} size={20} color={'rgba(255, 255, 255,0.8)'}></Entypo>
                  </Pressable>
                </View>
              </View>
          }
          <View style={{ flexDirection: "row" }}>
            {/* <Pressable onPress={() => {
              console.log('broadcaster')
            }} style={{ position: "absolute", left: -85, backgroundColor: "rgb(90,192,234)", top: -15, borderTopStartRadius: 20, borderBottomStartRadius: 20, }}>
              <FontAwesome6 name="tower-broadcast" size={24} color="white" style={{ padding: 22 }} />
            </Pressable> */}
          <Pressable style={{ flex: 2, minWidth: 100, right: visibleState ? -50 : undefined }} onPress={() => { if (!visibleState) { } else { navigation.replace('Settings', { screen: 'Profile' }) } }} >
            <Badge overlap="circular" badgeContent={badgeSum()} color={availabilityColor(availableState)} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} variant="dot">
              <GetAvatarImage style={[{ position: "absolute", right: 45, top: -30, flex: 1, backgroundColor: theme ? 'white' : 'black', borderColor: availabilityColor(availableState), borderWidth: 3, margin: 5, minHeight: 70, minWidth: 70, borderRadius: 10, aspectRatio: 1 }]} />
            </Badge>
          </Pressable>
          </View>
        </LinearGradient>
        <MessageSettings style={{ flex: 1 }} />
        <FlipCard
          flipHorizontal={true}
          flipVertical={false}
          flip={messageFlip}
          clickable={false}
          onFlipEnd={() => {
            if (!messageFlip) {
              clearBadge(selectedConversation);
              setMessageArray([])
              GiftedChat.append([], [])
            }
          }}
          style={{ backgroundColor: 'transparent', flex: 1 }}
        >
          <View style={{ flex: 1, backgroundColor: theme ? '#58BFEA' : '#3a8099' }}>
            <LinearGradient
              // Background Linear Gradient
              colors={theme ? ['#1E90FF', 'transparent'] : ['#333333', 'black']}
              style={{ height: '100%', flex: 1 }}>
              <FlashList
                //Sort data so item with highest badge count is at the top
                data={data.sort((a, b) => b.badge - a.badge)}
                estimatedItemSize={100}
                  keyExtractor={(item) => item?._id}
                  renderItem={renderUserItems}
                onLayout={(event) => {
                  const { width } = event.nativeEvent.layout;
                  setListWidth(width)
                }}
                  horizontal={false}
                  showsVerticalScrollIndicator={false}
                  scrollEnabled={true}
                style={{ paddingRight: 5 }}
                ListHeaderComponent={<RenderHeader visibleState={visibleState} setQuery={setQuery} toggleSwitch={toggleSwitch} handleSearch={handleSearch} setSearchVisible={setSearchVisible} />}
                />
              <Pressable onPress={() => { dispatch({ type: SHOW_HELP_MODAL }) }} style={[styles.centerEverything, {
                minHeight: 40, backgroundColor: theme ? 'rgb(90,192,234)' : "black", paddingVertical: 15, alignSelf: 'stretch', shadowRadius: 20, shadowOpacity: .2
              }]}>
                < Entypo name="help" size={24} color="white" style={styles.centerEverything} />
              </Pressable>
            </LinearGradient>
          </View>
          <View style={[styles.back, { backgroundColor: theme ? "white" : "grey" }]}>
            <View style={{ flex: .1, gap: 10, backgroundColor: theme ? "white" : "grey", flexDirection: 'row', padding: 10, borderBottomWidth: 2, borderColor: '#ececec', maxHeight: 70 }}>
              <View style={{ flex: 1, maxWidth: 60 }}>
                {
                  __DEV__ &&
                  <Text>{selectedConversation._id}</Text>
                }
                <Image source={selectedConversation ? selectedConversation.avatar : null} style={selectedConversation ? [styles.messageAvatar, { marginHorizontal: 10 }] : null} />
              </View>
              <View style={{ flexDirection: 'row', flex: 3 }}>
                <View style={{ flex: 4 }}>
                <Text adjustsFontSizeToFit style={{ fontSize: 18, fontWeight: '500', color: theme ? "black" : "white" }}>{selectedConversation ? selectedConversation.name + ' ' + selectedConversation.surname : null}</Text>
                <Text adjustsFontSizeToFit style={{ fontSize: 14, fontWeight: '400', color: theme ? "black" : "white" }}>{selectedConversation ? toTitleCase(selectedConversation.department) : null}</Text>
                </View>
                <View style={[styles.centerEverything, { flexDirection: 'row-reverse', gap: 2, }]}>
                  <LottieView source={require('@assets/animations/107653-trophy.json')} autoPlay loop={false} style={{ width: 35, top: -2 }} />
                  <Text style={{ fontWeight: 'bold' }}>100</Text>
                </View>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              {
                conversationIdState && messageArray.length ?
                  <>
                    <ErrorBoundary>
                  <GiftedChat
                    messageContainerRef={containerRef}
                    messages={messageArray}
                    isTyping={false}
                    renderBubble={(props: BubbleProps<IMessage>) => {
                      return (
                        <ErrorBoundary>
                        <Bubble
                          renderMessageImage={renderMessageImage}
                          {...props}
                        />
                        </ErrorBoundary>
                      )
                    }
                    }
                    showUserAvatar
                    key={selectedConversation?._id + chatKey}
                    minInputToolbarHeight={100}
                    renderInputToolbar={(props: InputToolbarProps<any>) => <ErrorBoundary><InputToolbar inverse removeAttach forceColumn staticPress onSend={props.onSend} user={props.user} /></ErrorBoundary>}
                    onSend={messages => {
                      console.log(messages);
                      handleMessage(messages)
                    }}
                    user={{ _id: globalUser?.id, avatar: userData?.avatar?.img }}
                    renderSystemMessage={props => {
                      return (
                        <ErrorBoundary>
                        <Animatable.View animation={'bounceIn'}>
                        <SystemMessage
                          {...props}
                            containerStyle={{ marginHorizontal: 15, borderRadius: 10, padding: 10, shadowRadius: 20, shadowOpacity: .2 }}
                            textStyle={{ ...props?.textStyle, color: theme ? 'black' : 'white' }}
                        />
                        </Animatable.View>
                        </ErrorBoundary>
                      );
                    }}
                    renderMessageImage={props => {
                      return (
                        <ErrorBoundary>
                        <MessageImage
                          {...props}
                          containerStyle={{
                            width: 200,
                            height: 160
                          }}
                          imageStyle={{
                            width: widthPercentageToDP(10),
                            height: heightPercentageToDP(16),
                            borderRadius: 13,
                            margin: 3,
                            resizeMode: 'cover',
                          }}
                        />
                        </ErrorBoundary>
                      );
                    }}
                    locale={activeLanguage}
                    renderAvatar={(props) => (
                      <ErrorBoundary>
                      <Image
                        source={{ uri: props.currentMessage.user.avatar }}
                        style={{ minHeight: 35, minWidth: 35, borderRadius: 20 }}
                      />
                      </ErrorBoundary>
                    )}
                  />
                    </ErrorBoundary>
                  </>
                  :
                  <View style={{ flex: 1, alignSelf: "stretch", justifyContent: "center", alignContent: "center", alignItems: "center", minHeight: "auto" }}>
                    <LoadingIndicator />
                  </View>
              }
            </View>
          </View>
        </FlipCard>
      </Animatable.View >
    </Animatable.View>
  );
};

const mapStateToProps = function (state) {
  return {
    messages: state.modals.messageModal.messageContent?.messages,
    messageFlip: state.modals.messageModal.flipped,
    visibleState: state.modals.messageModal.modalDisplayed,
    convoId: state.modals.messageModal.messageContent?.conversationId,
    statusBarHeight: state.UI.static.statusBarHeight
  }
}

export default connect(mapStateToProps)(Messages);