import apis from '@api/api';
import {
	REQUEST,
	SUCCESS,
	FAILED,
	LOGGED_OUT,
	AVATAR_UPDATED,
	CLEAR_AVATAR,
	LOGO_UPDATED,
	CLEAR_ERRORS,
	REGISTER_SUCCESS,
	GOOGLE_AUTHENTICATED,
	GOOGLE_AUTH_FAILED,
	MAIL_RETRIEVED,
	GOOGLE_LOGOUT,
	CHANGE_DESK_IMAGE,
	UPDATE_ACHIEVEMENTS,
	SWITCH_COMPANY_REQUEST,
	SWITCH_COMPANY_SUCCESSFUL,
	SWITCH_COMPANY_FAILED,
	HOLD_REGISTER_STATE_VALUES,
	CLEAR_REGISTER_STATE_VALUES,
	CLEAR_MAIL_AUTH,
	GOOGLE_SIGNIN,
	AWAITINGMFA,
	UPDATE_GOOGLE_ACCOUNT,
	GOOGLE_CALENDARS_ADDED,
	ADD_CALENDAR_TOKEN,
	RESET_APP,
	HANDLE_FAVOURITE,
	REFRESH,
	ADD_INTEGRATION,
	REMOVE_INTEGRATION,
	SET_TUTORIAL_COMPLETE,
	ADD_LOCAL_CALENDARS,
	ADD_LIKE_TO_POST,
	ADD_COMMENT_TO_POST,
	DELETE_COMMENT_FROM_POST,
	DELETE_LIKE_FROM_POST,
	UPDATE_GOOGLE_MAIL_TOKEN,
	REMOVE_MAIL_SCOPE,
	REFRESH_GOOGLE_TOKEN,
} from '../actions/types';
import { clearPersistState, updateObject } from './utils/globalStateFunctions';
import { FLUSH, PURGE } from 'redux-persist';

const INITIAL_STATE = {
	loading: false,
	message: {},
	isAuthenticated: false,
	success: null,
	data: {
		userData: {
			authentications: {},
			calendar: {},
			favourites: {
				projects: [],
				data: [],
				news: [],
			},
			posts: { liked_posts: [] },
		},
	},
};

const authReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ADD_INTEGRATION:
			return updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						authentications: {
							...state.data.userData.authentications,
							[action.payload.authType]: action.payload.data,
						},
					},
				},
			});
		case CLEAR_MAIL_AUTH:
			return updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						authentications: {
							...state.data.userData.authentications,
							mailAuthenticated: false,
							mail: {},
						},
					},
				},
			});
		case REMOVE_MAIL_SCOPE:
			console.log('removing mail scope');
			return updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						authentications: {
							...state.data.userData.authentications,
							mail: {
								...state.data.userData.authentications.mail,
								mailAuthenticated: false,
							},
							[action.payload.authType]: {
								[action.payload.authType.scopes]: [
									...state.data.userData.authentications[
										action.payload.authType.scopes
									].filter(
										(scope) => scope !== action.payload
									),
								],
							},
						},
					},
				},
			});
		case UPDATE_GOOGLE_MAIL_TOKEN:
			return updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						authentications: {
							...state.data.userData.authentications,
							google: {
								...state.data.userData.authentications.google,
								accessToken: action.payload,
							},
						},
					},
				},
			});
		case LOGGED_OUT:
			return updateObject(state, INITIAL_STATE);
		case HOLD_REGISTER_STATE_VALUES:
			const heldValues = action.payload.values;
			return updateObject(state, {
				heldValues: heldValues,
			});
		case CLEAR_REGISTER_STATE_VALUES:
			return updateObject(state, {
				heldValues: {},
			});
		case ADD_LIKE_TO_POST:
			// Clone the liked_posts array and push the new payload into it
			const newLikedPosts = [
				...state.data.userData.posts.liked_posts,
				action.payload,
			];

			return updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						posts: {
							...state.data.userData.posts,
							liked_posts: newLikedPosts,
						},
					},
				},
			});

		case DELETE_LIKE_FROM_POST:
			const updatedLikedPosts =
				state.data.userData.posts.liked_posts.filter(
					(post) => post !== action.payload
				);

			const likedPosts =
				updatedLikedPosts.length > 0 ? updatedLikedPosts : [];

			return updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						posts: {
							...state.data.userData.posts,
							liked_posts: likedPosts,
						},
					},
				},
			});
		case HANDLE_FAVOURITE:
			const { id, type, body } = action.payload;
			console.log(id, type, body);

			const updatedUserData = { ...state.data.userData };

			// Ensure that favourites and favourites[type] are initialized
			if (!updatedUserData.favourites) {
				updatedUserData.favourites = {};
			}
			if (!updatedUserData.favourites[type]) {
				updatedUserData.favourites[type] = [];
			}

			// Determine the item to search for, using body if it exists, otherwise id
			const item = body ? body : id;

			// Check if the item already exists in the favourites array
			const existingIndex =
				updatedUserData.favourites[type].indexOf?.(item);

			if (existingIndex !== -1) {
				// Item exists, remove it from the array
				updatedUserData.favourites[type] = [
					...updatedUserData.favourites[type].slice?.(0, existingIndex),
					...updatedUserData.favourites[type].slice?.(
						existingIndex + 1
					),
				];
			} else {
				// Item does not exist, add it to the array
				updatedUserData.favourites[type] = [
					...updatedUserData.favourites[type],
					item,
				];
			}

			return updateObject(state, {
				data: {
					...state.data,
					userData: updatedUserData,
				},
			});
		case SET_TUTORIAL_COMPLETE:
			return updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						tutorials: {
							...state.data.userData.tutorials,
							firstTutorial: true,
						},
					},
				},
			});
		case RESET_APP:
			return updateObject(state, INITIAL_STATE);
		case UPDATE_ACHIEVEMENTS:
			return updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						achievements: {
							...state.data.userData.achievements,
							achievementPoints: action.payload,
						},
					},
				},
			});
		case REFRESH:
			return updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						achievements: {
							...state.data.userData.achievements,
							achievementPoints: action.payload.achievements,
						},
						favourites: {
							...state.data.userData.favourites,
							favourites: action.payload.favourites,
						},
						profile_information: action.payload.profile_information,
						posts: {
							...state.data.userData.posts,
							posts: action.payload.posts,
						},
					},
				},
			});
		case REFRESH_GOOGLE_TOKEN:
			return updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						authentications: {
							...state.data.userData.authentications,
							google: {
								...state.data.userData.authentications.google,
								accessToken: action.payload,
							},
						},
					},
				},
			});
		case UPDATE_GOOGLE_ACCOUNT:
			return updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						authentications: {
							...state.data.userData.authentications,
							mailAuthenticated: action.payload.scopes.includes(
								'https://www.googleapis.com/auth/gmail.modify'
							),
							mailType: action.payload.scopes.includes(
								'https://www.googleapis.com/auth/gmail.modify'
							)
								? 'google'
								: state.data.userData.authentications
										?.mailType || '',
							google: action.payload,
						},
					},
				},
			});
		case ADD_CALENDAR_TOKEN:
			return updateObject(state, {
				google: {
					...state.google,
					gCal: action.payload,
				},
			});
		case SWITCH_COMPANY_REQUEST:
			apis.switchCompany(action.payload.userId, action.payload.companyId);
			return updateObject(state, {
				loading: true,
			});
		case SWITCH_COMPANY_SUCCESSFUL:
			return updateObject(state, {
				loading: false,
				data: {
					...state.data,
					companyData: action.payload.companyData,
				},
			});
		case SWITCH_COMPANY_FAILED:
			return updateObject(state, {
				loading: false,
			});
		case REQUEST:
			return updateObject(state, {
				loading: true,
			});
		case MAIL_RETRIEVED:
			return updateObject(state, {
				mailRetrieved: Date.now(),
			});
		case GOOGLE_AUTH_FAILED:
			return updateObject(state, {
				google: {
					...state.google,
					googleAuthenticated: false,
					googleCredentials: null,
				},
			});
		case GOOGLE_LOGOUT:
			return updateObject(state, {
				google: {
					...state.google,
					googleAuthenticated: false,
					googleCredentials: null,
					gMail: false,
					gCal: false,
					gDrive: false,
				},
				mailRetrieved: null,
			});
		case SUCCESS:
			return updateObject(state, {
				...state,
				message: {},
				data: action.data,
				loading: false,
				isAuthenticated: true,
			});
		case AWAITINGMFA:
			return updateObject(state, {
				message: {},
				data: action.data,
				loading: false,
				isAuthenticated: false,
			});
		case CLEAR_AVATAR:
			return updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						avatar: {
							...state.data.userData.avatar,
							img: undefined,
						},
					},
				},
			});
		case AVATAR_UPDATED:
			updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						avatar: {
							...state.data.userData.avatar,
							img: '',
						},
					},
				},
			});
			return updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						avatar: {
							...state.data.userData.avatar,
							img: action.data,
						},
					},
				},
			});
		case LOGO_UPDATED:
			return updateObject(state, {
				data: {
					...state.data,
					companyData: {
						...state.data.companyData,
						companyLogo: {
							...state.data.companyData.companyLogo,
							img: action.data,
						},
					},
				},
			});
		case CHANGE_DESK_IMAGE:
			return updateObject(state, {
				data: {
					...state.data,
					userData: {
						...state.data.userData,
						dashboardImage: {
							...state.data.userData.dashboardImage,
							img: action.data,
						},
					},
				},
			});
		case GOOGLE_AUTHENTICATED:
			return updateObject(state, {
				google: {
					...state.google,
					googleAuthenticated: true,
					googleCredentials: action.payload,
				},
			});
		case REGISTER_SUCCESS:
			console.log(action.payload.message);
			return updateObject(state, {
				message: action.payload.message,
			});
		case FAILED:
			return updateObject(state, {
				error: action.payload.message,
			});
		case CLEAR_ERRORS:
			clearPersistState();
			return updateObject(state, {
				error: {},
			});
		default:
			return state;
	}
};

export default authReducer;
