import { combineReducers } from 'redux';
import navigationReducer from './navigationReducer';
import appReducer from './appReducer';
import authReducer from './asyncReducer';
import modalReducer from './modalReducer';
import errorReducer from './errorReducer';
import uiReducer from './uiReducer';
import tutorialReducer from './tutorialReducer';
import uploadPreviewReducer from './uploadPreviewReducer';
import achievementReducer from './achievementReducer';
import widgetReducer from './widgetReducer';
import { RESET_APP } from '../actions/types';
import toolbarReducer from './toolbarReducer';
import storeReducer from './storeReducer';
import calendarReducer from './calendarReducer';
import notificationReducer from './notificationReducer';
import userHierarchyReducer from './userManagementReducer';
import projectReducer from './projectReducer';
import reportReducer from './reportReducer';

const rootReducers = (state, action) => {
	return allReducers(state, action);
};

function lastAction(state = null, action) {
	return action;
}

const allReducers = combineReducers({
	promiseData: authReducer,
	app: appReducer,
	navigation: navigationReducer,
	modals: modalReducer,
	errors: errorReducer,
	UI: uiReducer,
	uploadPreview: uploadPreviewReducer,
	notifications: notificationReducer,
	tutorials: tutorialReducer,
	calendar: calendarReducer,
	widgets: widgetReducer,
	achievements: achievementReducer,
	toolbar: toolbarReducer,
	store: storeReducer,
	lastAction: lastAction,
	userHierarchy: userHierarchyReducer,
	project: projectReducer,
	report: reportReducer,
});

export default rootReducers;
