import { useCallback, useEffect, useState } from 'react';
import { View, Pressable, Text, Platform } from 'react-native';
import * as WebBrowser from "expo-web-browser";
import * as Google from "expo-auth-session/providers/google";
import { HIDE_LOADING_MODAL, INITIALISE_MENU, SHOW_AUTH_MODAL, SHOW_LOADING_MODAL, SHOW_OTP, SHOW_TC_MODAL, SUCCESS } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import { createURL } from 'expo-linking';
import { GoogleIcon } from '@assets/--native';
import { navigationRef } from '../../../../rootNavigation';
import apis from '@api/api';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import getTCConfirm from '@components/modals/TCModal/utils/getTCConfirm';
import DeviceInfo from 'react-native-device-info';

WebBrowser.maybeCompleteAuthSession();



const GoogleButton = () => {
  const dispatch = useDispatch();
  //Google Authentication Vars
  const [token, setToken] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const useNavigation = navigationRef;
  const notificationToken: string = useSelector(
    (state: DefaultRootState) => state.app.notificationToken
  );

  const [request, response, promptAsync] = Google.useAuthRequest({
    androidClientId: "530616870665-mt8uulmtk3r41m5ga22sfuc66j632gik.apps.googleusercontent.com",
    iosClientId: "530616870665-sn6qm81jg6eh4pr6dm2ov2n6guovrgna.apps.googleusercontent.com",
    expoClientId: '530616870665-5e22kgtr0e146vutn8veh5shoqnkis5j.apps.googleusercontent.com',
    webClientId: '530616870665-5e22kgtr0e146vutn8veh5shoqnkis5j.apps.googleusercontent.com',
    redirectUri: createURL(''),
    scopes: ['email', 'profile']
  });



  const handleClick = async () => {
    await promptAsync()
      .then((res) => {
      })
  }

  useEffect(() => {
    if (response?.type === "success") {
      setToken(response.authentication.accessToken);
      getUserInfo(token)
    }
  }, [token, response]);



  async function getUserInfo(token) {
    if (response?.type != 'success') {
      dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Failed to authenticate Google Account, please try again', type: 'error' } })
    }
    if (!token) {
      return
    }
    try {
      const response = await fetch(
        "https://www.googleapis.com/userinfo/v2/me",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const user = await response.json();
      const newOAuthUser = {
        surname: user.family_name,
        name: user.given_name,
        id: user.id,
        email: user.email,
        oauthToken: token,
        type: 'google',
        notificationToken: notificationToken,
        device: { brand: DeviceInfo.getBrand(), model: DeviceInfo.getModel(), os: DeviceInfo.getSystemName(), osVersion: DeviceInfo.getSystemVersion() }
      }

      //TODO Terms and conditions modal required here! Ensure terms and conditions are placed appropriately and are only visible if the user does not already exist in the database

      try {
        dispatch({ type: SHOW_LOADING_MODAL })
        await apis.handleOAuth(newOAuthUser)
          .then(async (res) => {
            //TODO add user response handler to retrieve remaining data objects, invites emails etc
            if (res.status === 202) {
              console.log("user has mfa");
              if (res.data.terms) {
                console.log("user has terms and conditions to accept")
                dispatch({ type: SHOW_TC_MODAL, payload: res.data.userId });
                try {
                  await getTCConfirm().then(confirm => {
                    console.log('received a response from the tc Confirmation', confirm);
                    return dispatch({ type: SHOW_OTP, payload: res.data });
                  })
                } catch (e) {
                  setTimeout(() => dispatch({ type: SHOW_AUTH_MODAL, data: { content: e ? e.toString() : "Please accept our terms of service to continue", type: 'error' } }), 1000);
                  return;
                }
              } else {
                return dispatch({ type: SHOW_OTP, payload: res.data });
              }
            } else {
              if (res.data.terms) {
                console.log(res.data);
                dispatch({ type: HIDE_LOADING_MODAL })
                setTimeout(() => dispatch({ type: SHOW_TC_MODAL, payload: res.data.data.userData.id }), 1000);
                try {
                  await getTCConfirm().then(confirm => {
                    console.log('received a response from the tc Confirmation', confirm);
                  }).catch(e => console.error(e))
                } catch (e) {
                  console.log('an error occurred awaiting tcs', e);
                  setTimeout(() => dispatch({ type: SHOW_AUTH_MODAL, data: { content: "Could not fetch terms and conditions, please try again", type: 'error' } }), 1000);
                  return;
                }
              }
              let amendedData = res.data.data;
              if (Platform.OS != 'web') {
                let activeTokens: string[] = [];
                if (amendedData.notification_info) {
                  amendedData.notification_info.map(item => activeTokens.push(item.notification_token));
                }
                //TODO compare token from other device tokens and ensure that it does not exist already
                if (!activeTokens.length || activeTokens.includes(notificationToken)) {
                  await apis.updateNotificationToken(
                    notificationToken,
                    amendedData.userData.id
                  );
                }
                amendedData.expireAt = Date.now() + 1000 * 60 * 60 * 24 * 7;
              }
              dispatch({ type: SUCCESS, data: amendedData });
              useNavigation.navigate('Dashboard');
            }
          }).finally(() => { dispatch({ type: HIDE_LOADING_MODAL }) })
      } catch (e) {
        console.error('an error occurred awaiting tcs', e);
        setTimeout(() => dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Failed to authenticate Google Account, please try again', type: 'error' } }), 1000);
        return;
      }
    } catch (e) {
      console.log('an error occurred in the google button component');
      setTimeout(() => dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Failed to authenticate Google Account, please try again', type: 'error' } }), 1000);
      return;
    }
  };


  return (
    <Pressable
      onPress={handleClick} style={{ elevation: 1, shadowRadius: 10, shadowOpacity: .2, marginVertical: 10, backgroundColor: 'white', borderRadius: 30, minWidth: 270, padding: 10, flexDirection: 'row', justifyContent: 'center', alignContent: 'space-around', alignItems: 'stretch', }}>
      <GoogleIcon />
      <View style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, justifyContent: 'center', alignItems: 'center', minWidth: 220 }}><Text adjustsFontSizeToFit style={{ color: 'black', fontWeight: '600' }}>Sign in with Google</Text></View>
    </Pressable >
  );
}

export default GoogleButton;