import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { FlatList } from "react-native";
import { clearDB, fetchLogs } from '@reduxLocal/persistState';
import { View, Text, Title } from "@constants/Themed";
import moment from "moment";

const LogViewer = forwardRef((props, ref) => {
  const [logs, setLogs] = useState([]);

  useImperativeHandle(ref, () => ({
    refresh: async () => {
      const logsData = await fetchLogs();
      setLogs(logsData.reverse().slice(0, 50));
    },
    clear: async () => {
      //Empty the DB
      await clearDB();
      setLogs([]);
    },
    getLogCount: async () => {
      console.log(logs.length, 'log length');
      return logs.length;
    }
  }));

  useEffect(() => {
    const loadLogs = async () => {
      const logsData = await fetchLogs();
      setLogs(logsData.reverse().slice(0, 50));
    };

    loadLogs();

    const interval = setInterval(loadLogs, 5000); // Polling every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <FlatList
      data={logs}
      renderItem={({ item, index }) => {
        return (
          <View style={{ padding: 10, backgroundColor: index % 2 ? '#D3D3D3' : undefined, marginVertical: 5, flexDirection: "row" }}>
            <View style={{ flex: 4 }}>
              <View style={{ flexDirection: "row" }}>
                {
                  item.type !== 'API_REQUEST' ?
                    <Title style={{ flex: 1 }}>{item.action}</Title>
                    :
                    <Title style={{ flex: 1 }}>{item.type}</Title>
                }
                <Text style={{ flex: 1, textAlign: "right", opacity: .4, fontSize: 10 }}>{moment(item.timestamp).format("HH:MM:SSSS a")}{'\n'}{moment(item.timestamp).format("Do MMM YYYY")}</Text>
              </View>
              <Text style={{ fontWeight: "bold", top: -5 }}>{item.method || JSON.stringify(item.payload)}</Text>
              <Text>{item.url}</Text>
            </View>
          </View>
        )
      }} />
  )
})
export default LogViewer;