import * as React from 'react';
import { Pressable, View, Text, Dimensions, ScrollView } from 'react-native';
import styles from '../../../stylesheet';
import * as WebBrowser from 'expo-web-browser';
import { LinearGradient } from 'expo-linear-gradient';
import FlipCard from 'react-native-flip-card';
import DynamicPlanInfo from './DynamicPlanInfo';
import DynamicContentInfo from './components/DynamicContentInfo';
import { FlatList } from 'react-native-gesture-handler';
import { useQuery, useQueryClient } from 'react-query';
import BlogItem from './components/BlogItem';
import LottieView from 'lottie-react-native';
import MarkDown from 'react-native-markdown-display';
import {rules} from '@utils/stringUtils/markDownRules';
import * as Animatable from 'react-native-animatable';

function learnMorePress() {
  WebBrowser.openBrowserAsync(
    'https://www.smartsaas.co.uk'
  )
}
function privacyPolicy() {
  WebBrowser.openBrowserAsync(
    'https://www.smartsaas.co.uk/privacy-policy'
  )
}
function termsOfService() {
  WebBrowser.openBrowserAsync(
    'https://www.smartsaas.co.uk/terms-and-conditions/'
  )
}

function handleGoToBlog() {
  WebBrowser.openBrowserAsync(
    'https://www.smartsaas.co.uk/posts'
  )
}

const _renderItem = ({ item, index }) => {
  return (
    <BlogItem item={item} index={index} />
  )
}

const DynamicContent = ({ registerModalState, selectedPlanState }) => {
  const [postData, setPostData] = React.useState([]);
  const [journal, setJournal] = React.useState(false);
  const queryClient = useQueryClient();
  const [changelogContent, setChangelogContent] = React.useState('');
  const changeLogData = queryClient.getQueryData('changeLogData');


  React.useEffect(() => {
    if(changeLogData) {
      setChangelogContent(changeLogData.toString());
    }
  }, []);



  const { status, data, error, isFetching, isLoading, refetch } = useQuery({
    queryKey: ['postData'],
    queryFn: async () => {
      const data = await fetch('https://smartsaas.co.uk/wp-json/wp/v2/posts', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(async response => {
          if (response.status === 200) {
            const data = await response.json();
            return data;
          }
        }
        );
        setJournal(true);
      return data
    },
    retry: 20,
  })

  return (
    <View style={{ flex: 1.5, zIndex: 5, overflow: 'visible' }}>
      <View style={{ zIndex: 1, flex: 1 }}>
        <View style={{ flexDirection: 'column', flex: 1, overflow: "visible" }}>
          <FlipCard
            style={[styles.credentialInputContainer, { flex: 1, flexDirection: 'column' }]}
            flipVertical={true}
            flip={registerModalState}
            clickable={false}
          >
            <View style={[styles.face, { flex: 1, maxWidth: '100%', gap: 20, overflow: 'visible' }]}>
              <DynamicContentInfo />
              <View style={{ flex: 1, marginTop: 10, flexDirection: 'row', minWidth: '100%', alignSelf: 'stretch' }}>
                <View style={{ flex: 1, gap: 20, top: 60, maxWidth: 40 }}>
                  <Pressable onPress={() => setJournal(false)} style={{ backgroundColor: journal ? "white" : 'rgb(90,192,234)', height: 150, borderTopStartRadius: 20, borderBottomStartRadius: 20 }}>
                    <Text numberOfLines={1} style={[styles.textRotate, { userSelect: "none" }]}>Changelog</Text>
                  </Pressable>
                  <Pressable onPress={() => setJournal(true)} style={{ backgroundColor: journal ? 'rgb(90,192,234)' : "white", height: 150, borderTopStartRadius: 20, borderBottomStartRadius: 20 }}>
                    <Text numberOfLines={1} style={[styles.textRotate, { userSelect: "none" }]}>Journal</Text>
                  </Pressable>
                </View>
                <LinearGradient colors={['#ffffff66', 'transparent']} style={{ borderRadius: 20, flex: 5, top: 40 }}>
                {
                  !isLoading &&
                    <Animatable.View transition={['backgroundColor']} style={{borderRadius: 20, backgroundColor: journal ? "transparent" : "white", flex: 5 }}>
                      {
                        journal ? 
                            <FlatList style={{ flex: 1 }} showsVerticalScrollIndicator={false} scrollEnabled keyExtractor={item => item.id} data={data} maxToRenderPerBatch={3} initialNumToRender={3} contentContainerStyle={{ padding: 20, paddingBottom: 200 }} nestedScrollEnabled renderItem={_renderItem} ItemSeparatorComponent={() => (<View style={{ minHeight: 20 }}></View>)}
                              ListEmptyComponent={() => (<View style={{ flexDirection: 'row' }}>
                                <LottieView autoPlay style={{ width: 80, margin: 20 }} source={require('../../../assets/animations/errorIcon.json')} loop={true} speed={1} />
                                <Text style={{ verticalAlign: 'middle', paddingVertical: 40 }}>Could not download recent posts, please check your network connection</Text>
                              </View>)}
                               />
                            :
                            <ScrollView style={{ flex: 1, marginBottom: 50 }} contentContainerStyle={{padding: 30}}>
                              <MarkDown rules={rules}>{changelogContent}</MarkDown>
                            </ScrollView>
                        }
                      </Animatable.View>
                }
              </LinearGradient>
              </View>
            </View>
            {/*Card Flips Here!*/}
            <LinearGradient
              colors={['rgba(255,255,255,0.8)', 'transparent']}
              style={[styles.back, { flex: 1, alignSelf: 'stretch', paddingVertical: 20, paddingHorizontal: 20, borderRadius: 20 }]}
            >
              {
                selectedPlanState ?
                <DynamicPlanInfo />
                :
                  <Text adjustsFontSizeToFit >Please select a plan to get more details</Text>
              }
              <Text adjustsFontSizeToFit >Sign up for our free 14 day trial today and experience what SmartSaaS can do for your business</Text>
              <Text adjustsFontSizeToFit >No Credit Card Required!</Text>
            </LinearGradient>
          </FlipCard>
        </View>
      </View >
    </View >
  );
};

export default DynamicContent;