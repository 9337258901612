import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { View, Pressable } from 'react-native';
import { useDispatch, useSelector } from "react-redux";
import { DefaultRootState } from "@reduxLocal/persistState";
import { CONTEXT_SIZE_TOGGLE, TOGGLE_CONTEXT } from "@reduxLocal/actions/types";
import * as Animatable from 'react-native-animatable';
import { themeSelector } from "@reduxLocal/selectors/themeSelector";
import styles from "../../../stylesheet";
import { FontAwesome } from "@expo/vector-icons";
import { Platform } from 'react-native';

const PageContext = ({ route, navigation, returnContext }) => {
  const [dynamicComponent, setDynamicComponent] = useState(null);

  useEffect(() => {
    const fetchDynamicComponent = async () => {
      const component = await returnContext();
      setDynamicComponent(component);
    };
    fetchDynamicComponent();
    return () => {
      setDynamicComponent(null);
    }
  }, [route, navigation, returnContext]);

  return dynamicComponent ? React.cloneElement(dynamicComponent, { navigation, route }) : null;
};

const ContextHandler = ({ route, navigation }) => {


  const currentScreen = useSelector((state: DefaultRootState) => state.navigation.page);
  const minimized = useSelector((state: DefaultRootState) => state.UI.contextMinimized);
  const [loaded, setLoaded] = useState(false);
  const theme = themeSelector();
  const [hasContext, setHasContext] = useState(false);
  const [isMinimized, setIsMinimized] = useState(minimized);
  const dispatch = useDispatch();
  
  useEffect(() => {
    setIsMinimized(minimized);
  }, [minimized])

  useEffect(() => {
    setLoaded(true);
    //On dismount, destroy context
    return () => {

    }
  }, [currentScreen]);

  const returnContext = useCallback(async () => {
    const importFrom = () => {
      const routesAvailable = currentScreen.state?.routes;
      return routesAvailable ? routesAvailable[routesAvailable.length - 1].name : currentScreen.name;
    };

    try {
      console.log('looking for context in: ', `../screens/MidLevel/${currentScreen.name}Screen/Context/${importFrom()}Context`);
      const context = await import(`../screens/MidLevel/${currentScreen.name}Screen/Context/${importFrom()}Context`);
      dispatch({ type: TOGGLE_CONTEXT, payload: true });
      setHasContext(true);
      return context.default;
    } catch (e) {
      setHasContext(false);
      dispatch({ type: TOGGLE_CONTEXT, payload: false });
      return null;
    }
  }, [currentScreen, hasContext]);

  const dynamicComponent =()=> {
    if (Platform.OS === 'web')
    return <PageContext navigation={navigation} route={route} returnContext={returnContext} />;
  }

  return (
    <Animatable.View transition={['minWidth']} direction='alternate' style={{ overflow: "visible", backgroundColor: theme ? ((minimized || !hasContext) ? "transparent" : "white") : ((minimized || !hasContext) ? "transparent" : "#f3f3f3"), minHeight: "90%", pointerEvents: !hasContext ? "none" : undefined, maxHeight: "100%", flex: 1, minWidth: isMinimized ? 140 : 400, marginRight: 10, paddingRight: 60, maxWidth: isMinimized ? 140 : 400, }}>
      {loaded && (
        <Animatable.View transition={['opacity', 'padding']} direction='alternate' style={[styles.contextContainer, { flex: 1, paddingVertical: "5%", padding: isMinimized ? 5 : 10, minWidth: "100%", paddingBottom: 60, opacity: hasContext ? 1 : 0 }]}>
          <Pressable onPress={() => dispatch({ type: CONTEXT_SIZE_TOGGLE })} style={{ left: -30, zIndex: -1, top: 20, position: "absolute", minWidth: 30, minHeight: 60, backgroundColor: "rgb(90,192,234)", borderTopStartRadius: 20, borderBottomStartRadius: 20 }}>
            <Animatable.View style={{ transform: [{ rotate: isMinimized ? '180deg' : '0deg' }] }}>
              <FontAwesome name={"arrow-circle-right"} size={25} color="white" style={{ top: isMinimized ? -15 : 15, left: isMinimized ? 2 : 5 }} />
            </Animatable.View>
          </Pressable>
          {dynamicComponent()}
        </Animatable.View>
      )}
    </Animatable.View>
  )
};

export default ContextHandler;
