export default function availabilityColor(availableState: string) {
	switch (availableState) {
		case 'available':
			return '#06c258';
		case 'busy':
			return 'orange';
		case 'dnd':
			return 'red';
		case 'ooo':
			return 'grey';
		default:
			return 'green';
	}
}
