import * as React from 'react';
import { useSelector } from 'react-redux';
import { DefaultRootState } from '@reduxLocal/persistState';
import { View, Text } from 'react-native';
import { Pressable, StyleSheet } from 'react-native';
import { Entypo, Feather, FontAwesome5, Foundation } from '@expo/vector-icons';
import styles from '../../../stylesheet';
import TabNavigator from '../TabNavigation/TabNavigator';
import { ADD_TAB, CLOSE_SECOND_MENU, SET_MESSAGE_APP_FILTER, SHOW_LOCK_MODAL, TOGGLE_MESSAGE_MODAL } from '@reduxLocal/actions/types';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import { uniqueId } from 'lodash';
import { useNavigation } from '@react-navigation/native';
import { BlurView } from '@constants/Themed';
import { MobileChatIcon, SmartSaaSIcon } from "@assets/--native";
import { FontAwesome6 } from "@expo/vector-icons";
import MenuItem from '@mui/material/MenuItem';
import toTitleCase from '@hooks/toTitleCase';
import { selectUser } from '@reduxLocal/selectors/userSelectors';
import { Popover, Badge, BadgeProps, styled } from '@mui/material';

export type ChatTabProps = {
  bgColor: string;
  icon: string | JSX.Element;
  title: string;
  callback: () => void;
  active?: boolean;
};

const chatFeatures: ChatTabProps[] = [
  { title: 'default', bgColor: "rgb(90,192,234)", icon: <SmartSaaSIcon fill={"black"} height={24} width={24} />, callback: () => { } },
  { title: 'messenger', bgColor: "#00B2FF", icon: <FontAwesome6 name="facebook-messenger" size={24} color="black" />, callback: () => dispatch({ type: SHOW_MESSAGE_MODAL }) },
  { title: 'whatsapp', bgColor: "#25D366", icon: <FontAwesome6 name="whatsapp" size={24} color="black" />, callback: () => { } },
  { title: 'viber', bgColor: "#8f5db7", icon: <FontAwesome6 name="viber" size={24} color="black" />, callback: () => { } },
  { title: 'linkedIn', bgColor: "#0077B5", icon: <FontAwesome6 name="linkedin" size={24} color="black" />, callback: () => { } },
]

const AddTab = () => {
  const navigation = useNavigation();
  const currentTab = useSelector((state: DefaultRootState) => state.navigation.tabs.length);
  const { authentications } = selectUser();
  const idGen = uniqueId();
  const theme = themeSelector();
  const dispatch = useDispatch();
  const [availableFeatures, setAvailableFeatures] = useState([]);

 

  React.useEffect(() => {
    const availableFeatures = chatFeatures.filter((feature) => Object.keys(authentications).includes(feature.title));
    setAvailableFeatures(availableFeatures);
  }, [chatFeatures])

  if (currentTab < 8) {
    return (
      <Pressable style={[TabStyles.addTabStyle, { backgroundColor: theme ? undefined : 'black' }]} onPress={() => { dispatch({ type: ADD_TAB, payload: { active: true, id: idGen, page: 'Welcome' } }), navigation.push('Welcome') }}>
        <Entypo name={"plus"} size={20} color={theme ? "black" : "white"}></Entypo>
      </Pressable >
    )
  } else {
    return (
      <Pressable style={[[TabStyles.addTabStyle, { backgroundColor: theme ? undefined : 'black' }]]} >
        <Entypo name={"plus"} size={20} color={theme ? "black" : "white"}></Entypo>
      </Pressable >
    )
  }
}


const FooterBar: any = () => {
  const messageAppFilter = useSelector((state: DefaultRootState) => state.UI.messageAppFilter);
  let menuOpen = useSelector((state: DefaultRootState) => state.UI.menuOpen);
  const [messageHovered, setMessageHovered] = useState(false);
  const [socialHovered, setSocialHovered] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const theme = themeSelector();
  const [messageState, setMessageState] = useState(null);
  
  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -8,
      top: -2,
      border: `0px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));
  return (
    <View style={[styles.centerEverything, { alignSelf: 'stretch', flex: 1, paddingHorizontal: menuOpen ? 200 : 150, paddingLeft: 100, maxWidth: '100%', elevation: 1, shadowRadius: 10, shadowOpacity: 0.2, flexDirection: 'row', backgroundColor: theme ? '#58BFEA' : '#3a8099', borderTopLeftRadius: 100, borderTopRightRadius: 100, paddingRight: 60, justifyContent: 'center', alignItems: 'center', top: -57 }]}>
      {/* <BlurView style={{ minWidth: "100%", position: "absolute", left: -15, zIndex: -10, shadowRadius: 20, shadowColor:"white", top: -30, alignSelf: "stretch", minHeight: 100, maxHeight:100, borderTopStartRadius: 20, borderTopEndRadius: 20 }} /> */}
      <AddTab />
      <LinearGradient
        // Background Linear Gradient
        style={[styles.centerEverything, { flexDirection: 'row', shadowRadius: 20, shadowOpacity: .2, alignSelf: 'center', flex: 1, flexGrow: 3, borderTopLeftRadius: 100, borderTopRightRadius: 100 }]}
        colors={theme ? ['#1E90FF', '#58BFEA'] : ['grey', 'black']}>
        <View style={{ flex: 7, marginLeft: 20 }}>
          <TabNavigator />
        </View>
        <View style={{ flex: 1, flexDirection: 'row', gap: 5, left: -50 }}>
          <Pressable style={[socialHovered ? styles.footerButtonHovered : styles.footerButton, { flexDirection: 'row', flex: 1, minWidth: 30 }]} onPress={() => { navigation.navigate('NetworkingScreen') }} onHoverIn={() => { setSocialHovered(true) }} onHoverOut={() => { setSocialHovered(false) }}>
            <Foundation name={"share"} style={[styles.footerButtonIcon, { alignContent: 'center', textAlign: 'center', alignSelf: 'center' }]} size={20} ></Foundation>
          </Pressable>
          <Pressable style={[messageHovered || menuOpen ? styles.footerButtonHovered : styles.footerButton, { flexDirection: 'row', flex: 1, minWidth: 30, alignContent: "center", alignItems: "center", justifyContent: "center" }]} onHoverIn={() => { setMessageHovered(true) }} onHoverOut={() => { setMessageHovered(false) }} onPress={() => dispatch({ type: TOGGLE_MESSAGE_MODAL, payload: null })}>
            <Feather name={"message-circle"} style={[styles.footerButtonIcon, { alignContent: 'center', textAlign: 'center', alignSelf: 'center' }]} size={20} ></Feather>
          </Pressable>
          <View >
            <Popover
              open={!!messageState}
              anchorEl={messageState}
              onClose={() => setMessageState(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 30,
                horizontal: 'center',
              }}
              sx={{ minWidth: 400, borderRadius: 20 }}
              style={{ minWidth: 400, padding: 10, borderRadius: 5 }}
            >
              {
                chatFeatures.map((feature) => {
                  return (
                    <MenuItem
                      key={feature.title}
                      style={{ paddingInline: 20, flex: 1, minHeight: 50, backgroundColor: feature.title === messageAppFilter ? "rgb(90,192,234)" : undefined, flexDirection: 'row', gap: 5, flex: 1, minWidth: 250, marginBottom: 10, padding: 5, alignContent: "center", alignItems: "center", justifyContent: "center" }}
                      onClick={() => { dispatch({ type: SET_MESSAGE_APP_FILTER, payload: feature.title }); setMessageState(null) }}
                      sx={{ minWidth: 300, typography: 'body2', py: 1 }}
                    >
                      <Badge badgeContent={10} color="error" style={{ maxWidth: 50, flex: 1, minWidth: 40, left: 15 }} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                        <View style={{ flex: 0.5, minWidth: 40 }}>
                          {feature.icon}
                        </View>
                      </Badge>
                      <Text style={{right: 15, textAlign: "right", flex: 5, color: 'black', fontSize: 14, fontWeight: 'bold' }}>{feature.title === 'default' ? "SmartSaaS" : toTitleCase(feature.title)}</Text>
                    </MenuItem>
                  )
                })
              }
            </Popover>
            <Pressable onPress={(e) => setMessageState(e.currentTarget)}>
              <StyledBadge overlap="circular" badgeContent={100} color='error' anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                <View style={{ flex: 1, top: 5 }}>
                  {
                    chatFeatures.filter((feature) => feature.title === messageAppFilter)[0]?.icon
                  }
                </View>
              </StyledBadge>
            </Pressable>
          </View>
        </View>
      </LinearGradient>
    </View>
  )
}

const TabStyles = StyleSheet.create({
  addTabStyle: {
    position: "absolute", marginTop: 6, left: "5%", borderTopLeftRadius: 30, minWidth: 80, borderTopRightRadius: 0, overflow: 'hidden', borderColor: 'black', paddingHorizontal: 15, paddingVertical: 10, backgroundColor: '#8BBEDC', elevation: 1, shadowRadius: 5, shadowOpacity: .2, alignContent: 'center'
  }
})

export default FooterBar;