import React from "react";
import { View } from '@constants/Themed';
import { ActivityIndicator, Platform } from 'react-native';

const LoadingIndicator = ({type, color}) => {
  if (type = 'processing') {
      return (
        <ActivityIndicator size='large' color={color || "#61c0ec"}></ActivityIndicator>
      )
  } else {
    return (
      <View></View>
    )
  }
}

export default LoadingIndicator;