import { useSelector, useDispatch } from "react-redux";
import { DefaultRootState } from "@reduxLocal/persistState";
import Tabs from "./Components/Tabs";
import { View } from "react-native";
import * as Animatable from 'react-native-animatable';
import React from "react";


const TabNavigator = () => {
  const currentTab = useSelector((state: DefaultRootState) => state.navigation.tabs.length);
  const openTabs = useSelector((state: DefaultRootState) => state.navigation.tabs)
  const activeTab = openTabs.filter((tabs) => tabs.active)

  const fadeIn = {
    from: {
      flex: 0.0001,
    },
    to: {
      flex: 0.14,
    },
  };



  return (
    <View style={{ flex: 20, paddingRight: 50, alignSelf: "stretch", gap: 5, justifyContent: "space-around", flexDirection: 'row' }} >
      {currentTab > 0 ?
        openTabs.map((tabs: { id: any; page: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, k: React.Key | null | undefined) => {
          return (
            <Animatable.View style={{ flex: 1, alignSelf: "stretch", minWidth: openTabs.length > 1 ? `${100 / openTabs?.length}%` : "100%" }} key={k} animation={fadeIn} duration={300}>
              <Tabs  {...{ tabs, activeTab, currentTab }} key={k} />
            </Animatable.View>
          )
        }
        )
        : null
      }
    </View>
  )
}

export default TabNavigator;
